import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { take } from 'rxjs/operators';
import { AgendamentoBackupModel } from 'src/app/models/agendamento-backup';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoBackupService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(schedduling): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })

    return this.http.post(`${GlobalConstants.apiURL}/executions/backup-activate/create`, schedduling, { headers, responseType: 'text' }).pipe(take(1))
  }

  public getAll() {
    return this.http.get<AgendamentoBackupModel[]>(`${GlobalConstants.apiURL}/executions/backup-activate`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public delete(id): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })


    return this.http.delete(`${GlobalConstants.apiURL}/executions/backup-activate/delete`, {
      params: {
        id: id
      },
      headers, responseType: 'text'
    }).pipe(take(1))
  }
}
