import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { take } from 'rxjs/operators';
import { AmbienteHomologacaoModel } from 'src/app/models/ambiente-homologacao';

@Injectable({
  providedIn: 'root'
})
export class AmbienteHomologacaoService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(ambiente: AmbienteHomologacaoModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })
      
    

    return this.http.post(`${GlobalConstants.apiURL}/enviroments/homologation`, ambiente, {headers, responseType: 'text'}).pipe(take(1))
  }

  public getBuilds(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/enviroments/homologation/builds`, httpOptions)
  }

  public getBuildsById(id): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/enviroments/homologation/builds/${id}`, httpOptions)
  }

  public getLogById(id): Observable<any>{
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })

    return this.http.get(`${GlobalConstants.apiURL}/enviroments/homologation/builds/log/${id}`,{headers, responseType: 'text'})
  }
}
