import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CriacaoSrvIntegracaoService } from '../criacao-srv-integracao.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-log-criacao-srv-integracao',
  templateUrl: './log-criacao-srv-integracao.component.html',
  styleUrls: ['./log-criacao-srv-integracao.component.css']
})
export class LogCriacaoSrvIntegracaoComponent implements OnInit {
  log: string
  lines: string
  id: number
  
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private criacaoSrvIntegracaoService: CriacaoSrvIntegracaoService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.criacaoSrvIntegracaoService.getLogById(this.id).subscribe((dados)=>{
      // this.log = dados
      let obj = JSON.parse(dados)
      this.lines= obj.log.split("\n")
      
    })
  }

  onBack(){
    this.location.back()
  }

}
