import { Component, OnInit } from '@angular/core';
import { ClientService } from '../client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.css']
})
export class ListClientComponent implements OnInit {

  items = []
  clientes = []
  pageOfItems: Array<any>

  constructor(
    private clientService: ClientService,
    private router: Router ) { }

  ngOnInit() {
    this.clientService.getAll().subscribe(dados => {
      dados.forEach((x)=>{
        if(x.disabled===0){
          this.clientes.push(x)
        }
      })
      this.clientes = this.clientes.sort((a,b) =>(a.name > b.name)? 1: -1)
      this.items = Array(0);
      for (let i = 0; i < this.clientes.length; i++) {
        this.items.push(this.clientes[i])
      }
      
    })
    
  }

  onEdit(id){
    this.router.navigate(['cadastros/clientes/editar', id])
  }

  onDelete(id){
    this.clientService.disable(id).subscribe(dados =>{
      console.log(dados)
    })
    location.reload()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  
}
