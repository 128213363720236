import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientLinkService } from '../client-link.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client/client.service';

@Component({
  selector: 'app-register-client-links',
  templateUrl: './register-client-links.component.html',
  styleUrls: ['./register-client-links.component.css']
})
export class RegisterClientLinksComponent implements OnInit {

  form: FormGroup;
  clients: any
  ambientes:any 
  dedicado: boolean
  semidedicado: boolean

  constructor(
    private formBuilder: FormBuilder,
    private clientLinkService: ClientLinkService,
    private router: Router,
    private location: Location, 
    private route: ActivatedRoute,
    private clientService: ClientService
    ) { }
  
    ngOnInit() {
    this.form = this.formBuilder.group({
      url: [null, [Validators.required]],
      enviroment: [null, Validators.required],
      ClientId: [null, Validators.required],
      dedicated: [false],
      semiDedicated:[false]
    });
    this.ambientes = [
      { name: 'Produção', value: 'producao' },
      { name: 'Homologação', value: 'homologacao' },
      { name: 'Projetos', value: 'projetos' },
    ]


    this.clientService.getAll().subscribe(clients => {
      this.clients = []
      clients.forEach(client => {
        this.clients.push({ name: client.name, id: client.id })
      })
    })
  }
  
  onSubmit() {
    if (this.form.valid) {
      this.clientLinkService.create(this.form.value).subscribe(success => this.location.back(), error => alert(error.error.error));
    }
    else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo);
        controle.markAsTouched();
      });
    }
  }
  onBack() {
    this.location.back();
  }
}
