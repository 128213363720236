import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import { AmbienteConnectService } from './ambiente-connect.service';
import { map } from 'rxjs/operators';
import { ProdutoConnectService } from 'src/app/shared/register-produto-connect/produto-connect.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ambiente-connect',
  templateUrl: './ambiente-connect.component.html',
  styleUrls: ['./ambiente-connect.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AmbienteConnectComponent implements OnInit {

  form: FormGroup
  produtos: any
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any
  rules: any
  stringRules: any

  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private auth: AuthenticationService,
    private amb: AmbienteConnectService,
    private produtoConnectService: ProdutoConnectService,
    private ambienteConnectService: AmbienteConnectService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      codigo: ['0', Validators.required],
      url: [null, Validators.required],
      produto: [null, Validators.required],
      razaoSocial: [null, Validators.required],
      nome: [null, Validators.required],
      email: [null, Validators.required],
      cliente: [""],
      aprovador: [""],
      pat: [""],
      loggeduser: [null, Validators.required]

    })

    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )

    this.ambienteConnectService.getRules().subscribe(data =>{
      this.rules = data.Rules.length
      this.stringRules = `${200 - this.rules} ambientes disponiveis para criação`
    })

    this.produtoConnectService.getAll().subscribe(products => {
      this.produtos = []
      products.forEach(product => {
        
        this.produtos.push({ nome: product.name })
      })
    })


  }


  onSubmit() {
    if (this.form.valid) {
      //this.http.post('https://httpbin.org/post', JSON.stringify(this.form.value)).pipe(map(res => res)).subscribe(dados => console.log(dados))
      this.amb.post(this.form.value).pipe(map(res => res)).subscribe(dados => {
        this.form.get('url').setValue(null)
        this.form.get('url').markAsUntouched();
        this.form.get('produto').setValue(null)
        this.form.get('produto').markAsUntouched();
        this.form.get('razaoSocial').setValue(null)
        this.form.get('razaoSocial').markAsUntouched();
        this.form.get('nome').setValue(null)
        this.form.get('nome').markAsUntouched();
        this.form.get('email').setValue(null)
        this.form.get('email').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      },
        (error: any) => {
          this.modalText = error.message
          this.displayModal = true
        })
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  aplicaCssErro(campo) {
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo) {
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }

  onBack() {
    this.location.back()
  }

  
}
