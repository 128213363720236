import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClienteModel } from 'src/app/models/cliente';
import { TreeNode } from 'primeng/api/treenode';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getAll() {
    return this.http.get<ClienteModel[]>(`${GlobalConstants.apiURL}/commons/clients`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public getTreeTable() {
    return this.http.get(`${GlobalConstants.apiURL}/commons/clientstreetable`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
      .toPromise()
  }


  public create(product: ClienteModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.post(`${GlobalConstants.apiURL}/commons/clients/create`, product, httpOptions).pipe(take(1))
  }

  public disable(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj = {}
    return this.http.put(`${GlobalConstants.apiURL}/commons/clients/disable/${id}`, obj, httpOptions).pipe(take(1))
  }

  public update(id: number, obj: ClienteModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    return this.http.put(`${GlobalConstants.apiURL}/commons/clients/edit/${id}`, obj, httpOptions).pipe(take(1))
  }

  public delete(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.delete(`${GlobalConstants.apiURL}/commons/clients/disable/${id}`, httpOptions).pipe(take(1))
  }
}
