import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CustoVolumesService } from './custo-volumes.service';

@Component({
  selector: 'app-custo-volumes',
  templateUrl: './custo-volumes.component.html',
  styleUrls: ['./custo-volumes.component.css']
})
export class CustoVolumesComponent implements OnInit {
  ebs: GridDataResult;
  cols: any;
  loading = true;
  total = 0;
  totalOtimizado=0;
  totalDiscosNaoUtilizados=0;

  constructor(private custosVolumesService: CustoVolumesService,) { }

  ngOnInit(): void {
    this.custosVolumesService.getVolumes().subscribe((dado) => {
      let dados = dado['Volumes']
      dados.forEach(element => {
        element['Tags'].forEach(element2 => {
          if (element2['Key'] == 'Name') {
            element.Name = element2['Value']
          } else {
            element.Name = ''
          }
        });
        if (element['VolumeType'] == 'gp3') {
          let sizePrice = (parseFloat(element['Size']) * 0.08)
          let iopsPrice = 0.00
          element.Economia = `$ 0.00`
          element.SizePrice = `$ ${sizePrice.toFixed(2)}`
          if (parseFloat(element['Iops']) <= 3000) {
            element.SizeIops = `$ 0.00`
            iopsPrice = 0.00
          } else {
            iopsPrice = (parseFloat(element['Iops']) * 0.005)
            element.SizeIops = `$ ${iopsPrice.toFixed(2)}`
          }
          element.Total = `$ ${(sizePrice + iopsPrice).toFixed(2)}`
          element.TotalOtimizado = `$ ${(sizePrice + iopsPrice).toFixed(2)}`

        } else if (element['VolumeType'] == 'gp2') {
          element.SizePrice = `$ ${(parseFloat(element['Size']) * 0.1).toFixed(2)}`
          element.SizeIops = `$ 0.00`

          let pricegp3 = (parseFloat(element['Size']) * 0.08)
          let pricegp2 = (parseFloat(element['Size']) * 0.1)
          element.Total = `$ ${(parseFloat(element['Size']) * 0.1).toFixed(2)}`
          if (parseFloat(element['Iops']) <= 3000) {
            element.Upgrade = "Migrar para GP3"
            element.Economia = `$ ${(pricegp2 - pricegp3).toFixed(2)}`
            element.TotalOtimizado = `$ ${pricegp3.toFixed(2)}`
          } else {
            let priceIopsGp3 = parseFloat(element['Iops']) * 0.005
            if ((pricegp3 + priceIopsGp3) <= pricegp2) {
              element.Upgrade = "Migrar para GP3"
              element.Economia = `$ ${(pricegp2 - (pricegp3 + priceIopsGp3)).toFixed(2)}`
              element.TotalOtimizado = `$ ${(pricegp3 + priceIopsGp3).toFixed(2)}`
            } else {
              element.Upgrade = ""
              element.Economia = `$ 0.00`
              element.TotalOtimizado = `$ ${(parseFloat(element['Size']) * 0.1).toFixed(2)}`
            }
          }
        } else if (element['VolumeType'] == 'io1') {
          element.SizePrice = `$ ${(parseFloat(element['Size']) * 0.125).toFixed(2)}`
          element.SizeIops = `${(parseFloat(element['Iops']) * 0.065).toFixed(2)}`
          element.Total = `$ ${((parseFloat(element['Size']) * 0.125) + (parseFloat(element['Iops'])) * 0.065).toFixed(2)}`
          element.TotalOtimizado = `$ ${((parseFloat(element['Size']) * 0.125) + (parseFloat(element['Iops'])) * 0.065).toFixed(2)}`
          element.Economia = `$ 0.00`
        } else if (element['VolumeType'] == 'io2') {
          element.SizePrice = `$ ${(parseFloat(element['Size']) * 0.125).toFixed(2)}`
          let priceIops
          if (parseFloat(element['Iops']) <= 32000) {
            element.SizeIops = `$ ${(parseFloat(element['Iops']) * 0.065).toFixed(2)}`
            priceIops = parseFloat(element['Iops']) * 0.065
          } else if (parseFloat(element['Iops']) <= 64000) {
            element.SizeIops = `$ ${(parseFloat(element['Iops']) * 0.046).toFixed(2)}`
            priceIops = parseFloat(element['Iops']) * 0.046
          } else {
            element.SizeIops = `$ ${(parseFloat(element['Iops']) * 0.032).toFixed(2)}`
            priceIops = parseFloat(element['Iops']) * 0.032
          }
          element.Total = ((parseFloat(element['Size']) * 0.125) + priceIops).toFixed(2)
          element.TotalOtimizado = ((parseFloat(element['Size']) * 0.125) + priceIops).toFixed(2)
          element.Economia = '$ 0.00'
        }

        this.total += parseFloat(element.Total.split(" ")[1])
        this.totalOtimizado += parseFloat(element.Economia.split(" ")[1])

        if(element.State == "available"){
          this.totalDiscosNaoUtilizados += 1
        }
      })

      this.ebs = dados
      this.loading = false;
    })
    this.cols = [
      { field: "Name", header: "Nome" },
      { field: "VolumeId", header: "Id" },
      { field: "Size", header: "Tamanho(GB)" },
      { field: "VolumeType", header: "Tipo" },
      { field: "Iops", header: "Iops" },
      { field: "State", header: "Status" },
      { field: "SizePrice", header: "Valor(Disco)" },
      { field: "SizeIops", header: "Valor(Iops)" },
      { field: "Total", header: "Total" },
      { field: "Upgrade", header: "Recomendação" },
      { field: "Economia", header: "Economia" },
      { field: "TotalOtimizado", header: "Total Otimizado" },
    ]
  }

}
