import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import { ClienteModel } from 'src/app/models/cliente';
import { ClientService } from '../client.service';
import { Location, formatDate } from '@angular/common';

@Component({
  selector: 'app-new-list-client',
  templateUrl: './new-list-client.component.html',
  styleUrls: ['./new-list-client.component.css']
})
export class NewListClientComponent implements OnInit {

  multiSortMeta = []
  data: ClienteModel[]
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading=true

  constructor(private clientService: ClientService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {

    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.clientService.getAll().subscribe(data => {
      this.data = data
      this.loading = false;
    })

    this.cols = [
      { field: 'name', header: 'Nome' },
      { field: 'createdAt', header: 'Data Criação' },
      { field: 'updatedAt', header: 'Última Atualização' },

    ]

  }

  onDelete({dataItem}) {
    if(this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja Excluir??',
        header: 'Remover',
        accept: () => {
          this.clientService.delete(dataItem.id).subscribe((result) => {
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
    
    
  }

  onEdit({dataItem}) {
    this.router.navigate(['cadastros/clientes/editar',dataItem.id])
  }



}
