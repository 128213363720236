import { Component, OnInit } from '@angular/core';
import { TreeNode, MessageService } from 'primeng/api';
import { TamanhoNuvemService } from '../tamanho-nuvem.service';
import { TamanhoNuvemModel } from 'src/app/models/tamanho-nuvem';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-new-list-tamanho-nuvem',
  templateUrl: './new-list-tamanho-nuvem.component.html',
  styleUrls: ['./new-list-tamanho-nuvem.component.css']
})
export class NewListTamanhoNuvemComponent implements OnInit {
  dataTreeNodel: TreeNode[];
  files: TreeNode[];
  datas: TamanhoNuvemModel[]
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + 'TamanhoClientes.xlsx';
  cols: any[]
  display: boolean = false;
  graph: any;
  nameGraph: any;
  options: any;
  dataKendoProcess= {data:[], labels:[]}
  data: any;
  sizesDataOrd = []
  datesOrd = []
  dataKendoProcess2={data:[], labels:[]}
  dbSizesDataOrd = []
  texto = ''
  constructor(
    private tamanhoNuvemService: TamanhoNuvemService
  ) { }

  ngOnInit(): void {
    this.tamanhoNuvemService.gettree().then(data => {
      this.data = data
      this.dataTreeNodel = this.data.data
    });

    this.tamanhoNuvemService.getall().subscribe(data => this.datas = data)

    this.cols = [
      { field: 'cliente', header: 'Cliente' },
      { field: 'total', header: 'Tamanho Total' },
      { field: 'dbsize', header: 'Tamanho DB' },
      { field: 'anexos', header: 'Anexos' },
      { field: 'arquivos', header: 'Arquivos' },
      { field: 'bpmsimagens', header: 'BPMSimagens' },
      { field: 'chavederegistro', header: 'ChaveDeRegistro' },
      { field: 'configuracoes', header: 'Configurações' },
      { field: 'icones', header: 'Icones' },
      { field: 'imagensmeuslinks', header: 'ImagensMeusLinks' },
      { field: 'informativos', header: 'Informativos' },
      { field: 'mensagens', header: 'Mensagens' },
      { field: 'mxmconfigcomum', header: 'MXMConfigComum' },
      { field: 'temp', header: 'Temp' },
      { field: 'temporario', header: 'Temporario' },
      { field: 'treinamento', header: 'Treinamento' },
      { field: 'usuarios', header: 'Usuarios' }
    ]

  }

  exportexcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  showDialog(data) {
    let dates = []
    this.datesOrd = []
    let sizesData = []
    this.sizesDataOrd = []
    let dbSizesData = []
    this.dbSizesDataOrd = []
    this.nameGraph = data.nome
    this.tamanhoNuvemService.getGraphInfo(data).subscribe((data) => {
      data.forEach(element => {
        element.createdAt = formatDate(element.createdAt, 'dd/MM/yyyy', 'en')
        dates.push(element.createdAt)
        sizesData.push(element.totalsize)
        dbSizesData.push(element.dbsize)
      });
      for (let index = dates.length - 1; index >= 0; index--) {
        const element = dates[index];
        this.datesOrd.push(element)
      }
      for (let index = sizesData.length - 1; index >= 0; index--) {
        const element = sizesData[index];
        this.sizesDataOrd.push(element)
      }
      for (let index = dbSizesData.length - 1; index >= 0; index--) {
        const element = dbSizesData[index];
        this.dbSizesDataOrd.push(element)
      }
      let copyDates = []
      this.datesOrd.forEach(element => {
        copyDates.push(element)
      })
      this.datesOrd=[]
      copyDates.forEach((elements)=>{
        let element = elements.split("/")
        this.datesOrd.push(`${element[1]}/${element[2]}`)
      })
      this.dataKendoProcess= {data: this.sizesDataOrd, labels: this.datesOrd  }
      this.dataKendoProcess2= {data: this.dbSizesDataOrd, labels: this.datesOrd  }
      
      let maxSize = this.sizesDataOrd.reduce(function(a, b) {
        return Math.max(a, b);
      });
      let maxdbSize = this.dbSizesDataOrd.reduce(function(a, b) {
        return Math.max(a, b);
      });
      let max = 0
      if(maxSize > maxdbSize){
        max = maxSize
      } else{
        max = maxdbSize
      }

      if(max >=1000000){
        this.texto = 'GB'
      } else if(max >= 1000){
        this.texto = 'MB'
      } else {
        this.texto = 'KB'
      }

      this.display = true;
     

    })
  }

  selectData(event) {
    this.data.datasets[event.element._datasetIndex].data[event.element._index];
  }
  
  label(data){
    if(data.value >= 1000){
      return data.value/1000000
      
    } else if(data.value >= 1000000){
      return data.value/1000
      
    } else {
      return data.value
      
    }
  }
  public fetchChildren = (item) => {
    return item.contents;
  }
  public hasChildren = (item) => {
    return item.contents && item.contents.length > 0;
  }
}
