import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { process,State } from '@progress/kendo-data-query';
import { RelatorioAmbientesOneshareService } from './relatorio-ambientes-oneshare.service';

@Component({
  selector: 'app-relatorio-ambientes-oneshare',
  templateUrl: './relatorio-ambientes-oneshare.component.html',
  styleUrls: ['./relatorio-ambientes-oneshare.component.css']
})
export class RelatorioAmbientesOneshareComponent implements OnInit {

  loading = true;
  items = [];
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + '_Relatorios_Ambiente_Oneshare.xlsx';

  public state: State = {
    skip: 0,
    take: 5,
  };
  public gridData: GridDataResult = process(this.items, this.state);
  constructor(
    private relatorioAmbientesOneshareService: RelatorioAmbientesOneshareService,
    private router: Router
  ) { 
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.relatorioAmbientesOneshareService.getData().subscribe(dados => {
      this.items = dados
      this.loading = false;
      this.gridData = process(this.items, this.state);
      console.log(dados)
    })
  }

  public allData(): ExcelExportData {
    var myState: State = this.state;
    myState.skip = 0;
    myState.take = this.gridData.total;
    const result: ExcelExportData = {
      data: process(this.items, this.state).data
    };
    return result;
  }
}
