import { Component, OnInit, ChangeDetectorRef, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { VersionsService } from 'src/app/shared/register-version/versions.service';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { PublicacaoProducaoServiceService } from './publicacao-producao-service.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-publicacao-producao',
  templateUrl: './publicacao-producao.component.html',
  styleUrls: ['./publicacao-producao.component.css']
})
export class PublicacaoProducaoComponent implements OnInit {

  form: FormGroup
  versoes: any
  mostrar = false
  details: UserDetails
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any
  script:any

  constructor(private formBuilder: FormBuilder,
    private pub: PublicacaoProducaoServiceService,  
    private location: Location,
    private versionservice: VersionsService, 
    private auth: AuthenticationService,
    private cd: ChangeDetectorRef) { }
  
  ngOnInit() {
    this.form = this.formBuilder.group({
      urlDownload: [null, Validators.required],
      urlHibrido: [null, Validators.required],
      versao: [null, Validators.required],
      scriptBanco: [null, Validators.required],
      file:[null],
      user:[null],
      aprovador:[null],
      pat:[null, Validators.required],
      loggeduser:[null, Validators.required]
    })

    this.versionservice.getall().subscribe(versions => {
      this.versoes = []
      versions.forEach(version => {
        this.versoes.push({ versao: version.versao })
      })
    })

    this.script = [
      { name: 'Sim', value: 'Y' },
      { name: 'Não', value: 'N' },
    ]
    this.auth.profile().subscribe(
      user => {
        this.details = user
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
        this.form.get('user').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )

  }
  
  onSubmit(){
    if(this.form.valid){
      
      //this.http.post('https://httpbin.org/post', JSON.stringify(this.form.value)).pipe(map(res => res)).subscribe(dados => console.log(dados))
      this.pub.post(this.form.value).pipe(map(res=> res)).subscribe(dados=>{
        this.form.get('urlDownload').setValue(null)
        this.form.get('scriptBanco').setValue('N')
        this.form.get('urlDownload').markAsUntouched();
        this.form.get('urlHibrido').setValue(null)
        this.form.get('urlHibrido').markAsUntouched();
        this.form.get('file').setValue(null);
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      }, 
      (error: any)=> {
        this.modalText = error.message
        this.displayModal = true
      })  
    } else{
      Object.keys(this.form.controls).forEach((campo)=> {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }
  

  trocar(){
    
    if(this.form.get('scriptBanco').value.value == 'Y'){
      this.mostrar = true
    } else{
      this.mostrar = false
      this.form.get('file').setValue(null)
    }
  }

  onFileChange(event) {
    let reader = new FileReader();
   
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.form.patchValue({
          file: reader.result
        });
        
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onBack(){
    this.location.back()
  }
  
}
