import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { VersionsService } from 'src/app/shared/register-version/versions.service';
import { ClientService } from '../client/client.service';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AcaoManualService } from './acao-manual.service';

@Component({
  selector: 'app-acao-manual',
  templateUrl: './acao-manual.component.html',
  styleUrls: ['./acao-manual.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class AcaoManualComponent implements OnInit {

  form: FormGroup
  loggedUser: UserDetails
  mostrar: boolean = false
  ambientes: any
  versoes: any[]
  clientes: any[];
  displayModal: boolean
  modalText: any


  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private versionservice: VersionsService,
    private clientService: ClientService,
    private messageService: MessageService,
    private acaoManualService: AcaoManualService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      usuario: [null, Validators.required],
      aprovador: [null],
      pat: [null, Validators.required],
      ambiente: [null, Validators.required],
      cliente: [null],
      versao: [null, Validators.required],
      acao: [null, Validators.required],
      obs: [null]
    })


    this.auth.profile().subscribe(
      user => {
        this.loggedUser = user
        this.form.get("usuario").setValue(this.loggedUser.email)
      },
      err => {
        console.error(err)
      }
    )

    this.versionservice.getall().subscribe(versions => {
      this.versoes = []
      versions.forEach(version => {
        this.versoes.push({ versao: version.versao })
      })
    })

    this.clientService.getAll().subscribe(clients => {
      
      this.clientes = []
      clients.forEach(client => {
        this.clientes.push({ name: client.name, id: client.id })
      })
    })
    

    this.ambientes = [
      { name: 'Produção', value: 'producao' },
      { name: 'Homologação', value: 'homologacao' },
      { name: 'Projetos', value: 'projetos' },
    ]

  }
  onSubmit() {
    if (this.form.valid) {
      this.acaoManualService.post(this.form.value).subscribe(acao => {
        this.modalText = "Ação Cadastrada"
        this.displayModal = true
      },
      (error: any) => {
        this.modalText = error.message
        this.displayModal = true
      })
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  trocar() {

    if (this.form.get('ambiente').value.value == 'projetos') {
      this.mostrar = true
    } else {
      this.mostrar = false
      this.form.get('cliente').setValue(null)
    }
  }



}
