import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-acesso-base',
  templateUrl: './list-acesso-base.component.html',
  styleUrls: ['./list-acesso-base.component.css']
})
export class ListAcessoBaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
