import { Injectable } from '@angular/core';
import { UserDetails, AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  userDetail: UserDetails

  constructor(private auth: AuthenticationService, private router: Router) { }

  canActivate() {
    if (!this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/login')
      return false
    }
    this.auth.profile().subscribe(
      user => {
        this.userDetail = user
        if (this.userDetail.profile == "visualizacao") {
          this.router.navigateByUrl('/permissaonegada')
        }
        if (this.userDetail.profile == "onesharebuilder") {
          this.router.navigateByUrl('/permissaonegada')
        }
      },
      err => {
        console.error(err)
      }
    )

    return true
  }
}
