import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import {MenuItem} from 'primeng/api';
import { HomeService } from 'src/app/components/home/home.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  details: any;

  get myText(): string {
    return this.datas.currentMytext;
  };

  constructor(
    public auth: AuthenticationService,
    private datas: HomeService
    ) { }

  ngOnInit(): void {
    this.auth.profile().subscribe(
      user => {
        this.details = user
        this.datas.currentMytext = `${this.details.first_name} ${this.details.last_name}`
      },
      err => {
        console.error(err)
      }
    )
    
  }

}
