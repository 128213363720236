import { Component, OnInit } from '@angular/core';
import { VersionsService } from 'src/app/shared/register-version/versions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-versions',
  templateUrl: './list-versions.component.html',
  styleUrls: ['./list-versions.component.css']
})
export class ListVersionsComponent implements OnInit {

  items = []
  tipos = []
  pageOfItems: Array<any>

  constructor(
    private versionsService: VersionsService,
    private router: Router ) { }

  ngOnInit() {
    this.versionsService.getall().subscribe(dados => {
      dados.forEach((x)=>{
        if(x.disabled===0){
          this.tipos.push(x)
        }
      })
      this.tipos = this.tipos.sort((a,b) =>(a.name > b.name)? 1: -1)
      this.items = Array(0);
      for (let i = 0; i < this.tipos.length; i++) {
        this.items.push(this.tipos[i])
      }
      
    })
    
  }

  onEdit(id){
    this.router.navigate(['versao/editar', id])
  }

  onDelete(id){
    this.versionsService.disable(id).subscribe(dados =>{
      console.log(dados)
    })
    location.reload()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

}
