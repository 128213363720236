import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { PublicacaoHomologacaoModel } from 'src/app/models/publicacao-homologacao';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicacaoHomologacaoService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(publicacao: PublicacaoHomologacaoModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })
      
    

    return this.http.post(`${GlobalConstants.apiURL}/publications/homologation`, publicacao, {headers, responseType: 'text'}).pipe(take(1))
  }

  public getBuilds(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/publications/homologation/builds`, httpOptions)
  }

  public getBuildsById(id): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/publications/homologation/builds/${id}`, httpOptions)
  }

  public getLogById(id): Observable<any>{
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })

    return this.http.get(`${GlobalConstants.apiURL}/publications/homologation/builds/log/${id}`,{headers, responseType: 'text'})
  }

}
