import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgendamentoBackupService } from '../agendamento-backup.service';
import { AuthenticationService } from 'src/app/authentication.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-new-agendamento-backup',
  templateUrl: './new-agendamento-backup.component.html',
  styleUrls: ['./new-agendamento-backup.component.css']
})
export class NewAgendamentoBackupComponent implements OnInit {

  today = new Date()
  versoes: any[]
  hoursObj: any
  mostrar: boolean = false
  form: FormGroup;
  displayModal: boolean
  modalText: any

  constructor(
    private formBuilder: FormBuilder,
    private agendamentoBackupService: AgendamentoBackupService,
    private location: Location,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
    this.today.setDate(this.today.getDate())

    this.form = this.formBuilder.group({
      bucket: [null, [Validators.required]],
      cliente: [null, Validators.required],
      codigoCliente: [null, Validators.required],
      bdCliente: [null, Validators.required],
      diretorio: [null, Validators.required],
      user: [null, Validators.required]
    });
    


    this.auth.profile().subscribe(
      user => {
        this.form.get('user').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )

    
  }

  onSubmit() {
    if (this.form.valid) {
      this.agendamentoBackupService.post(this.form.value).pipe(map(res => res)).subscribe(dados => {
        this.form.get('bucket').setValue(null)
        this.form.get('bucket').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('diretorio').setValue(null)
        this.form.get('diretorio').markAsUntouched();
        this.form.get('codigoCliente').setValue(null)
        this.form.get('codigoCliente').markAsUntouched();
        this.form.get('bdCliente').setValue(null)
        this.form.get('bdCliente').markAsUntouched();
        

        this.modalText = "Agendamento feito"
        this.displayModal = true
      },
        (error: any) => {
          this.modalText = error.message
          this.displayModal = true
        })
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo);
        controle.markAsTouched();
      });
    }
  }
  onBack() {
    this.location.back();
  }


}
