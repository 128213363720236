import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VersionsService } from 'src/app/shared/register-version/versions.service';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import { AmbienteProducaoService } from './ambiente-producao.service';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';



@Component({
  selector: 'app-ambiente-producao',
  templateUrl: './ambiente-producao.component.html',
  styleUrls: ['./ambiente-producao.component.css']
})
export class AmbienteProducaoComponent implements OnInit {
  form: FormGroup
  versoes: any[]
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any

  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private versionservice: VersionsService, 
    private auth: AuthenticationService,
    private amb: AmbienteProducaoService,
    
    ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      codigo: [null, Validators.required],
      url: [null, Validators.required],
      razaoSocial: [null, Validators.required],
      usuarioAdmin:[null, Validators.required],
      versao:[null, Validators.required],
      cliente:[null, Validators.required],
      aprovador:[null],
      pat:[null, Validators.required],
      loggeduser:[null, Validators.required]
    })

    this.versionservice.getall().subscribe(versions => {
      this.versoes = []
      versions.forEach(version => {
        this.versoes.push({ versao: version.versao })
      })
    })
    
    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )
    

  }

 
  onSubmit(){
    if(this.form.valid){
      // //this.http.post('https://httpbin.org/post', JSON.stringify(this.form.value)).pipe(map(res => res)).subscribe(dados => console.log(dados))
      this.amb.post(this.form.value).pipe(map(res=> res)).subscribe(dados=>{
        this.form.get('codigo').setValue(null)
        this.form.get('codigo').markAsUntouched();
        this.form.get('url').setValue(null)
        this.form.get('url').markAsUntouched();
        this.form.get('razaoSocial').setValue(null)
        this.form.get('razaoSocial').markAsUntouched();
        this.form.get('usuarioAdmin').setValue(null)
        this.form.get('usuarioAdmin').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      }, 
      (error: any)=> {
        this.modalText = error.message
        this.displayModal = true
      })  
    } else{
      Object.keys(this.form.controls).forEach((campo)=> {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  aplicaCssErro(campo){
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo){
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }
  onBack(){
    this.location.back()
  }
}
