import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from '../global-constants';
import { Observable } from 'rxjs';
import { ProdutoConnectModel } from 'src/app/models/produto-connect';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProdutoConnectService {


  constructor(private http: HttpClient, private auth: AuthenticationService) { }
  
  public getAll() {
    return this.http.get<ProdutoConnectModel[]>(`${GlobalConstants.apiURL}/connect/products`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public create(product: ProdutoConnectModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.post(`${GlobalConstants.apiURL}/connect/products/create`, product, httpOptions).pipe(take(1))
  }

  public disable(id: number){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.put(`${GlobalConstants.apiURL}/connect/products/disable/${id}`,obj , httpOptions).pipe(take(1))
  }

  public update(id: number, obj: ProdutoConnectModel){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    return this.http.put(`${GlobalConstants.apiURL}/connect/products/edit/${id}`,obj , httpOptions).pipe(take(1))
  }

  public delete(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.delete(`${GlobalConstants.apiURL}/connect/products/disable/${id}`, httpOptions).pipe(take(1))
  }
}
