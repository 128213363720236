import { Component, OnInit } from '@angular/core';

import { PublicacaoProjetosService } from '../publicacao-projetos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-publicacao-projetos',
  templateUrl: './list-publicacao-projetos.component.html',
  styleUrls: ['./list-publicacao-projetos.component.css']
})
export class ListPublicacaoProjetosComponent implements OnInit {

  items = []
  builds = []
  pageOfItems: Array<any>;
  loading = true

  

  constructor(
    private publicacaoProjetosService: PublicacaoProjetosService,
    private router: Router) { }

  ngOnInit() {
    this.publicacaoProjetosService.getBuilds().subscribe(dados => {
      dados.forEach((x) => {
        this.builds.push(x)
      })
      this.builds = this.builds.sort((a, b) => (a.number < b.number) ? 1 : -1)
      this.items = Array(0);
      for (let i = 0; i < this.builds.length; i++) {
        this.items.push(this.builds[i])
      }
      this.loading = false;
    })

  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onInfo({dataItem}) {
    this.router.navigate(['publicacao/projetos/info', dataItem.number])
  }
 
}
