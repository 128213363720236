import { Component, ViewEncapsulation } from '@angular/core'
import { AuthenticationService, UserDetails } from '../../authentication.service'
import { Router } from '@angular/router'

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent {
  details: UserDetails
  id: number

  constructor(private auth: AuthenticationService,
     private router: Router) {}

  ngOnInit() {
    this.auth.profile().subscribe(
      user => {
        this.details = user
        this.id = this.details.id
      },
      err => {
        console.error(err)
      }
    )
  }

  onEdit(id){
    this.router.navigate(['perfil/alterar-senha', id])
  }
}
