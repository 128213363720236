import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TypeintegrationsService } from './typeintegrations.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-register-typeintegrations',
  templateUrl: './register-typeintegrations.component.html',
  styleUrls: ['./register-typeintegrations.component.css']
})
export class RegisterTypeintegrationsComponent implements OnInit {

  form: FormGroup

  constructor(private formBuilder: FormBuilder,
    private typeIntegrationService: TypeintegrationsService,
    private router: Router,
    private location: Location  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]]
    })
  }

  aplicaCssErro(campo){
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo){
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }

  onSubmit(){
    
    if(this.form.valid){
      this.typeIntegrationService.create(this.form.value).subscribe(
        success => this.location.back(),
        error => alert(error.error.error)
      )
    } else{
      Object.keys(this.form.controls).forEach((campo)=> {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
    
  }

  onBack(){
    this.location.back()
  }
}
