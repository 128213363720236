import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { map } from 'rxjs/operators';
import { AmbienteHomologacaoService } from './ambiente-homologacao.service';

@Component({
  selector: 'app-ambiente-homologacao',
  templateUrl: './ambiente-homologacao.component.html',
  styleUrls: ['./ambiente-homologacao.component.css']
})
export class AmbienteHomologacaoComponent implements OnInit {
  form: FormGroup
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any

  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private auth: AuthenticationService,
    private amb: AmbienteHomologacaoService,

  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      codigo: [null, Validators.required],
      url: [null, Validators.required],
      razaoSocial: [null, Validators.required],
      cliente: [null, Validators.required],
      aprovador: [null],
      pat: [null, Validators.required],
      loggeduser: [null, Validators.required]
    })

    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )


  }


  onSubmit() {
    if (this.form.valid) {
      // //this.http.post('https://httpbin.org/post', JSON.stringify(this.form.value)).pipe(map(res => res)).subscribe(dados => console.log(dados))
      this.amb.post(this.form.value).pipe(map(res => res)).subscribe(() => {
        this.form.get('codigo').setValue(null)
        this.form.get('codigo').markAsUntouched();
        this.form.get('url').setValue(null)
        this.form.get('url').markAsUntouched();
        this.form.get('razaoSocial').setValue(null)
        this.form.get('razaoSocial').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      },
        (error: any) => {
          this.modalText = error.message
          this.displayModal = true
        })
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  onBack() {
    this.location.back()
  }
}
