import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustoVolumesService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getVolumes() {
    return this.http.get(`${GlobalConstants.apiURL}/commons/describevolumes`, {
      headers: { Authorization: ` ${this.auth.getToken()}`}
    }).pipe(map(res=> res))
  }
}
