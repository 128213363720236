import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CriacaoSrvIntegracaoService } from '../criacao-srv-integracao.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-info-criacao-srv-integracao',
  templateUrl: './info-criacao-srv-integracao.component.html',
  styleUrls: ['./info-criacao-srv-integracao.component.css']
})
export class InfoCriacaoSrvIntegracaoComponent implements OnInit {
  
  parameters=[]
  id: number
  build
  date
  
  constructor(
    private route: ActivatedRoute,
    private criacaoSrvIntegracaoService: CriacaoSrvIntegracaoService,
    private location: Location,
    private router: Router
    
    ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.criacaoSrvIntegracaoService.getBuildsById(this.id).subscribe((dados)=>{
      this.build = dados
      this.parameters = this.build.actions[0].parameters
      this.date = new Date(dados.timestamp)
    })
  }

  onBack(){
    this.location.back()
    
  }
  onLog(){
    this.router.navigate(['integracao/criacaosrvintegracao/log', this.id])
  }
 
}
