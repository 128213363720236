import { Component } from "@angular/core";
import { AuthenticationService, TokenPayload } from "../../authentication.service";
import { Router } from "@angular/router";

@Component({
  templateUrl: "./register.component.html",
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  credentials: TokenPayload = {
    id: 0,
    first_name: "",
    last_name: "",
    profile: "",
    email: "",
    password: ""
  };

  constructor(private auth: AuthenticationService, private router: Router) {}

  register() {
    this.auth.register(this.credentials).subscribe(
      () => {
        
      },
      err => {
        console.error(err);
      }
    );
  }

  verificaValidTouched(campo){
    return !campo.valid && campo.touched;
  }
}
