import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PublicacaoHomologacaoService } from '../publicacao-homologacao.service';

@Component({
  selector: 'app-log-publicacao-homologacao',
  templateUrl: './log-publicacao-homologacao.component.html',
  styleUrls: ['./log-publicacao-homologacao.component.css']
})
export class LogPublicacaoHomologacaoComponent implements OnInit {
  log: string
  lines: string
  id: number
  
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private publicacaoHomologacaoService: PublicacaoHomologacaoService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.publicacaoHomologacaoService.getLogById(this.id).subscribe((dados)=>{
      // this.log = dados
      let obj = JSON.parse(dados)
      this.lines= obj.log.split("\n")
      
    })
  }

  onBack(){
    this.location.back()
  }
 
}
