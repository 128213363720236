import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientLinkService } from '../client-link.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ClientService } from '../../client/client.service';

@Component({
  selector: 'app-edit-client-links',
  templateUrl: './edit-client-links.component.html',
  styleUrls: ['./edit-client-links.component.css']
})
export class EditClientLinksComponent implements OnInit {

  id: number
  form: FormGroup
  clients: any
  ambientes:any 
  clientLink: any
  selectedAmbiente:any
  selectedCliente:any
  dedicado: boolean
  semidedicado: boolean

  constructor(private formBuilder: FormBuilder,
    private clientLinkService: ClientLinkService,
    private clientService: ClientService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    
    this.form = this.formBuilder.group({
      url: [null, [Validators.required]],
      enviroment: [null, Validators.required],
      ClientId: [null, Validators.required],
      dedicated: [false],
      semiDedicated:[false]
    });

    this.clientLinkService.get(this.id).subscribe((dados) => {
      this.clientLink = dados
      this.form.get('url').setValue(this.clientLink[0].url)
      let nameEnv
      if(this.clientLink[0].enviroment = 'producao'){
        nameEnv = 'Produção'
      } else if(this.clientLink[0].enviroment = 'homologacao') {
        nameEnv = 'Homologação'
      } else {
        nameEnv = 'Projetos'
      }
      this.selectedAmbiente = {name: nameEnv ,value: this.clientLink[0].enviroment}

      this.selectedCliente = {name: this.clientLink[0].client.name, id:this.clientLink[0].client.id}

      
      if(this.clientLink[0].dedicated == 1){
        this.form.get('dedicated').setValue(true)
      }
      
      if(this.clientLink[0].semi_dedicated == 1){
        this.form.get('semiDedicated').setValue(true)
      }
    })

    this.ambientes = [
      { name: 'Produção', value: 'producao' },
      { name: 'Homologação', value: 'homologacao' },
      { name: 'Projetos', value: 'projetos' },
    ]


    this.clientService.getAll().subscribe(clients => {
      this.clients = []
      clients.forEach(client => {
        this.clients.push({ name: client.name, id: client.id })
      })
    })
  }


  onSubmit() {

    if (this.form.valid) {
      this.clientLinkService.update(this.id, this.form.value).pipe(map(res => res)).subscribe(dados=>{
        this.location.back()
      },
      (error:any)=> alert(error.message))
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }

  }

  onBack() {
    this.location.back()
  }


}
