import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from 'src/app/shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CustosAwsService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public postDate(date): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })

    return this.http.post(`${GlobalConstants.apiURL}/commons/costbyservice`, date, { headers, responseType: 'json' }).pipe(take(1))
  }

  public getMonthCost() {
    return this.http.get(`${GlobalConstants.apiURL}/commons/monthtodatecosts`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
  public getCostForecast() {
    return this.http.get(`${GlobalConstants.apiURL}/commons/forecastmonthcosts`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
  public getCostByService() {
    return this.http.get(`${GlobalConstants.apiURL}/commons/costbyservice`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
  public getCostLastMonth() {
    return this.http.get(`${GlobalConstants.apiURL}/commons/costlastmonth`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
}
