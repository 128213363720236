import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { ProdutoConnectModel } from 'src/app/models/produto-connect';
import { ProdutoConnectService } from 'src/app/shared/register-produto-connect/produto-connect.service';

@Component({
  selector: 'app-new-list-produto-connect',
  templateUrl: './new-list-produto-connect.component.html',
  styleUrls: ['./new-list-produto-connect.component.css']
})
export class NewListProdutoConnectComponent implements OnInit {

  multiSortMeta = []
  data: ProdutoConnectModel[]
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading = true;
  constructor(private produtoConnectService: ProdutoConnectService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {

    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.produtoConnectService.getAll().subscribe(data => {
      this.data = data
      this.loading = false;
    })

    

  }


  onDelete({ dataItem }) {

    

    if(this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja excluir??',
        header: 'Remover',
        accept: () => {
          this.produtoConnectService.delete(dataItem.id).subscribe((result) => {
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
  }

  onEdit({ dataItem }) {
    this.router.navigate(['connect/produtos/editar', dataItem.id])
  }


}

