import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HomeService } from './home.service';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import { ClientLinkService } from '../clientlink/client-link.service';
import { LogsSistemaService } from '../logs-sistema/logs-sistema.service';
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  details: UserDetails
  dataUrl: any
  optionsUrl: any
  dataProcesso: any
  optionsProcesso: any
  linksProducao: number = 0
  linksHomologacao: number = 0
  linksProjetos: number = 0
  totalLinks: number = 0
  hoje = Date()
  diasGrafico = []
  totalOperacoesDias = [0,0,0,0,0,0,0,0,0,0]
  dataKendo = []
  dataKendoProcess={data:[], labels:[]}
  constructor(
    private datas: HomeService,
    public auth: AuthenticationService,
    public clientLinkService: ClientLinkService,
    public logsSistemaService: LogsSistemaService
  ) {}

  formatdate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }

  ngOnInit(): void {
    console.log(this.formatdate(this.hoje))
    for (let index = 9; index >= 0; index--) {
      let dia = new Date()
      dia.setDate(dia.getDate() - index)
      this.diasGrafico.push(this.formatdate(dia))
    }
    
    this.auth.profile().subscribe(
      user => {
        this.details = user
        this.datas.currentMytext = `${this.details.first_name} ${this.details.last_name}`
      },
      err => {
        console.error(err)
      }
    )

    this.clientLinkService.getAll().subscribe(urls => {
      urls.forEach(url => {
        if (url.enviroment == "producao") {
          this.linksProducao += 1
        } else if (url.enviroment == "homologacao") {
          this.linksHomologacao += 1
        } else {
          this.linksProjetos += 1
        }
        this.totalLinks += 1
        this.dataUrl = {
          labels: ['Produção', 'Homologação', 'Projetos'],
          datasets: [
            {
              data: [this.linksProducao, this.linksHomologacao, this.linksProjetos],
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56"
              ],
              hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56"
              ]
            },
          ]
        }

       
      })
      this.dataKendo = [
        {
          env:'Produção',
          value: this.dataUrl.datasets[0].data[0]
        },
        {
          env:'Homologação',
          value: this.dataUrl.datasets[0].data[1]
        },
        {
          env:'Projetos',
          value: this.dataUrl.datasets[0].data[2]
        }
      ]
      
    })

    this.optionsUrl = {
      title: {
        display: true,
        text: `Urls`,
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    };

    this.logsSistemaService.getAll().subscribe(logs=>{
      logs.forEach(log => {
        let date = new Date(log.createdAt.valueOf())
        for (let index = 9; index >= 0; index--) {
          if(this.formatdate(date) == this.diasGrafico[index]){
            this.totalOperacoesDias[index] += 1
          }
        }
        this.dataProcesso = {
          labels: this.diasGrafico,
          datasets: [
            {
              label: 'Processos',
              data: this.totalOperacoesDias,
              fill: false,
              borderColor: "#36A2EB"
            },
          ]
        }
        this.dataKendoProcess= {data: this.totalOperacoesDias, labels: this.diasGrafico  }
      })
    })

    

    



  }
  public labelContent(e: any): string {
    return `${ e.category }: \n ${e.value}`;
  }

}

