import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { ExclusaoAmbienteModel } from 'src/app/models/exclusao-ambiente';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExclusaoAmbienteService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(ambiente: ExclusaoAmbienteModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })
    return this.http.post(`${GlobalConstants.apiURL}/enviroments/delete`, ambiente, { headers, responseType: 'text' }).pipe(take(1))
  }

  public getAll() {
    return this.http.get<ExclusaoAmbienteModel[]>(`${GlobalConstants.apiURL}/enviroments/deletes`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
}
