import { Component, OnInit } from '@angular/core';
import { Location, formatDate} from '@angular/common';
import * as XLSX from 'xlsx';
import { AgendamentoBackupModel } from 'src/app/models/agendamento-backup';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { AgendamentoBackupService } from '../agendamento-backup.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-list-agendamento-backup',
  templateUrl: './list-agendamento-backup.component.html',
  styleUrls: ['./list-agendamento-backup.component.css']
})
export class ListAgendamentoBackupComponent implements OnInit {

  multiSortMeta = []
  data: AgendamentoBackupModel[]
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + 'SystemLogs.xlsx';
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading = true;

  constructor(
    private agendamentoBackupService: AgendamentoBackupService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.agendamentoBackupService.getAll().subscribe(data => {this.data = data; this.loading = false})

    this.cols = [
      { field: 'createdAt', header: 'Data do agendamento' },
      { field: 'user', header: 'Usuário' },
      { field: 'bucket', header: 'Ambiente' },
      { field: 'client', header: 'Cliente' },
      { field: 'directory', header: 'Versão' },
      { field: 'updatedAt', header: 'Última Atualização' },
      
    ]

  }

  exportexcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  onDelete({dataItem}){
    if(dataItem.user == this.user.email || this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja cancelar esse Serviço??',
        header: 'Cancelamento de serviço',
        accept: () => {
          this.agendamentoBackupService.delete(dataItem.id).subscribe((result)=>{
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
  }

}
