import { Component, OnInit } from '@angular/core';
import { ExclusaoAmbienteModel } from 'src/app/models/exclusao-ambiente';
import { ExclusaoAmbienteService } from '../exclusao-ambiente.service';

@Component({
  selector: 'app-list-exclusao-ambiente',
  templateUrl: './list-exclusao-ambiente.component.html',
  styleUrls: ['./list-exclusao-ambiente.component.css']
})
export class ListExclusaoAmbienteComponent implements OnInit {
  displayModal: boolean
  modalText: any
  conteudos: ExclusaoAmbienteModel[];

  cols: any[];
  constructor(
    private amb: ExclusaoAmbienteService
  ) { }

  ngOnInit(): void {
    this.amb.getAll().subscribe(data => this.conteudos = data)

    this.cols = [
      { field: 'client', header: 'Cliente' },
      { field: 'url', header: 'Url' },
      { field: 'approver', header: 'Aprovador' },
      { field: 'email', header: 'E-Mail' },
      { field: 'pat', header: 'PAT' },
      { field: 'createdAt', header: 'Data' },
      
  ];
  }

}
