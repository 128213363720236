import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { SystemLogModel } from 'src/app/models/system-log';

@Injectable({
  providedIn: 'root'
})
export class LogsSistemaService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getAll() {
    return this.http.get<SystemLogModel[]>(`${GlobalConstants.apiURL}/commons/systemlogs`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
}
