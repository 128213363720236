import { Component, OnInit } from '@angular/core';
import { AgendamentoPublicacaoModel } from 'src/app/models/agendamento-publicacao';
import { formatDate } from '@angular/common';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { AgendamentoPublicacaoService } from '../agendamento-publicacao.service';
import { ConfirmationService } from 'primeng/api';
import { Location} from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-allow-agendamento-publicacao',
  templateUrl: './allow-agendamento-publicacao.component.html',
  styleUrls: ['./allow-agendamento-publicacao.component.css']
})
export class AllowAgendamentoPublicacaoComponent implements OnInit {

  multiSortMeta = []
  data: AgendamentoPublicacaoModel[]
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + 'SystemLogs.xlsx';
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;

  constructor(
    private agendamentoPublicacaoService: AgendamentoPublicacaoService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'date', order: 1 });
    this.multiSortMeta.push({ field: 'time', order: 1 });
    this.agendamentoPublicacaoService.getAll().subscribe(data => this.data = data)

    this.cols = [
      { field: 'createdAt', header: 'Data do agendamento' },
      { field: 'date', header: 'Data Agendada' },
      { field: 'time', header: 'Horário' },
      { field: 'user', header: 'Usuário' },
      { field: 'environment', header: 'Ambiente' },
      { field: 'client', header: 'Cliente' },
      { field: 'version', header: 'Versão' },
      { field: 'updatedAt', header: 'Última Atualização' },
      { field: 'done', header: 'Executada' },
      
    ]

  }

  exportexcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  onDelete(data){
    if(data.user == this.user.email || this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja cancelar esse agendamento?',
        header: 'Cancelamento de Agendamento',
        accept: () => {
          this.agendamentoPublicacaoService.delete(data.id).subscribe((result)=>{
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
  }


}
