import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { ProfileComponent } from './components/profile/profile.component'
import { LoginComponent } from './components/login/login.component'
import { RegisterComponent } from './components/register/register.component'
import { HomeComponent } from './components/home/home.component'
import { AuthGuardService } from './auth-guard.service';
import { PublicacaoProducaoComponent } from './components/publicacao-producao/publicacao-producao.component'
import { RegisterVersionComponent } from './shared/register-version/register-version.component'
import { PublicacaoProjetosComponent } from './components/publicacao-projetos/publicacao-projetos.component'
import { CriacaoSrvIntegracaoComponent } from './components/criacao-srv-integracao/criacao-srv-integracao.component'
import { RegisterTypeintegrationsComponent } from './shared/register-typeintegrations/register-typeintegrations.component'
import { RegisterModuleintegrationsComponent } from './shared/register-moduleintegrations/register-moduleintegrations.component'
import { AmbienteProducaoComponent } from './components/ambiente-producao/ambiente-producao.component'
import { AtualizacaoVersaoComponent } from './components/atualizacao-versao/atualizacao-versao.component'
import { AmbienteConnectComponent } from './components/ambiente-connect/ambiente-connect.component'
import { PublicacaoHomologacaoComponent } from './components/publicacao-homologacao/publicacao-homologacao.component'
import { ListModuleintegrationsComponent } from './components/moduleIntegrations/list-moduleintegrations/list-moduleintegrations.component'
import { EditModuleintegrationsComponent } from './components/moduleIntegrations/edit-moduleintegrations/edit-moduleintegrations.component'
import { ListTypeIntegrationsComponent } from './components/typeintegrations/list-type-integrations/list-type-integrations.component'
import { EditTypeIntegrationsComponent } from './components/typeintegrations/edit-type-integrations/edit-type-integrations.component'
import { ListVersionsComponent } from './components/versions/list-versions/list-versions.component'
import { RegisterProdutoConnectComponent } from './shared/register-produto-connect/register-produto-connect.component'
import { ListProdutoConnectComponent } from './components/produtos-connect/list-produto-connect/list-produto-connect.component'
import { EditProdutoConnectComponent } from './components/produtos-connect/edit-produto-connect/edit-produto-connect.component'
import { ListClientComponent } from './components/client/list-client/list-client.component'
import { RegisterClientComponent } from './components/client/register-client/register-client.component'
import { EditClientComponent } from './components/client/edit-client/edit-client.component'
import { ListClientLinksComponent } from './components/clientlink/list-client-links/list-client-links.component'
import { RegisterClientLinksComponent } from './components/clientlink/register-client-links/register-client-links.component'
import { EditClientLinksComponent } from './components/clientlink/edit-client-links/edit-client-links.component'
import { ListUsersComponent } from './components/usuarios/list-users/list-users.component'
import { RegisterUsersComponent } from './components/usuarios/register-users/register-users.component'
import { EditUsersComponent } from './components/usuarios/edit-users/edit-users.component'
import { ListSrvIntegracaoComponent } from './components/criacao-srv-integracao/list-srv-integracao/list-srv-integracao.component'
import { InfoCriacaoSrvIntegracaoComponent } from './components/criacao-srv-integracao/info-criacao-srv-integracao/info-criacao-srv-integracao.component'
import { LogCriacaoSrvIntegracaoComponent } from './components/criacao-srv-integracao/log-criacao-srv-integracao/log-criacao-srv-integracao.component'
import { ListAmbienteConnectComponent } from './components/ambiente-connect/list-ambiente-connect/list-ambiente-connect.component'
import { InforAmbienteConnectComponent } from './components/ambiente-connect/infor-ambiente-connect/infor-ambiente-connect.component'
import { LogAmbienteConnectComponent } from './components/ambiente-connect/log-ambiente-connect/log-ambiente-connect.component'
import { ListAmbienteProducaoComponent } from './components/ambiente-producao/list-ambiente-producao/list-ambiente-producao.component'
import { InfoAmbienteProducaoComponent } from './components/ambiente-producao/info-ambiente-producao/info-ambiente-producao.component'
import { LogAmbienteProducaoComponent } from './components/ambiente-producao/log-ambiente-producao/log-ambiente-producao.component'
import { ListPublicacaoProducaoComponent } from './components/publicacao-producao/list-publicacao-producao/list-publicacao-producao.component'
import { InfoPublicacaoProducaoComponent } from './components/publicacao-producao/info-publicacao-producao/info-publicacao-producao.component'
import { LogPublicacaoProducaoComponent } from './components/publicacao-producao/log-publicacao-producao/log-publicacao-producao.component'
import { ListPublicacaoHomologacaoComponent } from './components/publicacao-homologacao/list-publicacao-homologacao/list-publicacao-homologacao.component'
import { InfoPublicacaoHomologacaoComponent } from './components/publicacao-homologacao/info-publicacao-homologacao/info-publicacao-homologacao.component'
import { LogPublicacaoHomologacaoComponent } from './components/publicacao-homologacao/log-publicacao-homologacao/log-publicacao-homologacao.component'
import { ListPublicacaoProjetosComponent } from './components/publicacao-projetos/list-publicacao-projetos/list-publicacao-projetos.component'
import { InfoPublicacaoProjetosComponent } from './components/publicacao-projetos/info-publicacao-projetos/info-publicacao-projetos.component'
import { LogPublicacaoProjetosComponent } from './components/publicacao-projetos/log-publicacao-projetos/log-publicacao-projetos.component'
import { EditVersionsComponent } from './components/versions/edit-versions/edit-versions.component'
import { ListAtualizacaoVersaoComponent } from './components/atualizacao-versao/list-atualizacao-versao/list-atualizacao-versao.component'
import { InfoAtualizacaoVersaoComponent } from './components/atualizacao-versao/info-atualizacao-versao/info-atualizacao-versao.component'
import { LogAtualizacaoVersaoComponent } from './components/atualizacao-versao/log-atualizacao-versao/log-atualizacao-versao.component'
import { ListLogsSistemaComponent } from './components/logs-sistema/list-logs-sistema/list-logs-sistema.component'
import { ReportClientLinksComponent } from './components/clientlink/report-client-links/report-client-links.component'
import { NotModService } from './permission/not-mod.service'
import { UsuarioService } from './permission/usuario.service'
import { ChangePasswordComponent } from './components/usuarios/change-password/change-password.component'
import { PermissionDeniedComponent } from './shared/permission-denied/permission-denied.component'
import { AmbienteHomologacaoComponent } from './components/ambiente-homologacao/ambiente-homologacao.component'
import { ListAmbienteHomologacaoComponent } from './components/ambiente-homologacao/list-ambiente-homologacao/list-ambiente-homologacao.component'
import { InfoAmbienteHomologacaoComponent } from './components/ambiente-homologacao/info-ambiente-homologacao/info-ambiente-homologacao.component'
import { LogAmbienteHomologacaoComponent } from './components/ambiente-homologacao/log-ambiente-homologacao/log-ambiente-homologacao.component'
import { AcaoManualComponent } from './components/acao-manual/acao-manual.component'
import { ExclusaoAmbienteComponent } from './components/exclusao-ambiente/exclusao-ambiente/exclusao-ambiente.component'
import { ListExclusaoAmbienteComponent } from './components/exclusao-ambiente/list-exclusao-ambiente/list-exclusao-ambiente.component'
import { AcessoBaseComponent } from './components/acesso-base/acesso-base.component'
import { ListTamanhoNuvemComponent } from './components/tamanho-nuvem/list-tamanho-nuvem/list-tamanho-nuvem.component'
import { NewListTamanhoNuvemComponent } from './components/tamanho-nuvem/new-list-tamanho-nuvem/new-list-tamanho-nuvem.component'
import { NewAgendamentoPublicacaoComponent } from './components/agendamento-publicacao/new-agendamento-publicacao/new-agendamento-publicacao.component'
import { ListAgendamentoPublicacaoComponent } from './components/agendamento-publicacao/list-agendamento-publicacao/list-agendamento-publicacao.component'
import { AllowAgendamentoPublicacaoComponent } from './components/agendamento-publicacao/allow-agendamento-publicacao/allow-agendamento-publicacao.component'
import { NewAgendamentoBackupComponent } from './components/agendamento-backup/new-agendamento-backup/new-agendamento-backup.component'
import { ListAgendamentoBackupComponent } from './components/agendamento-backup/list-agendamento-backup/list-agendamento-backup.component'
import { NewListClientLinksComponent } from './components/clientlink/new-list-client-links/new-list-client-links.component'
import { NewListClientComponent } from './components/client/new-list-client/new-list-client.component'
import { NewListProdutoConnectComponent } from './components/produtos-connect/new-list-produto-connect/new-list-produto-connect.component'
import { NewListModuleintegrationsComponent } from './components/moduleIntegrations/new-list-moduleintegrations/new-list-moduleintegrations.component'
import { NewListTypeIntegrationsComponent } from './components/typeintegrations/new-list-type-integrations/new-list-type-integrations.component'
import { NewListVersionsComponent } from './components/versions/new-list-versions/new-list-versions.component'
import { NewListUsersComponent } from './components/usuarios/new-list-users/new-list-users.component'
import { CustosAwsService } from './components/custos-aws/custos-aws.service'
import { CustosAwsComponent } from './components/custos-aws/custos-aws.component'
import { NotVisualizacaoService } from './permission/not-visualizacao.service'
import { CustoVolumesComponent } from './components/custo-volumes/custo-volumes.component'
import { RelatorioBaseVersaoComponent } from './components/relatorio-base-versao/relatorio-base-versao.component'
import { RelatorioAmbientesOneshareComponent } from './components/relatorio-ambientes-oneshare/relatorio-ambientes-oneshare.component'
import { RelatorioBaseInfoUsoService } from './components/relatorio-base-info-uso/relatorio-base-info-uso.service'
import { RelatorioBaseInfoUsoComponent } from './components/relatorio-base-info-uso/relatorio-base-info-uso.component'



const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },


  {
    path: 'publicacao/producao',
    component: ListPublicacaoProducaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/producao/criar',
    component: PublicacaoProducaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/producao/info/:id',
    component: InfoPublicacaoProducaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/producao/log/:id',
    component: LogPublicacaoProducaoComponent,
    canActivate: [NotModService]
  },


  {
    path: 'publicacao/homologacao',
    component: ListPublicacaoHomologacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/homologacao/criar',
    component: PublicacaoHomologacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/homologacao/info/:id',
    component: InfoPublicacaoHomologacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/homologacao/log/:id',
    component: LogPublicacaoHomologacaoComponent,
    canActivate: [NotModService]
  },


  {
    path: 'publicacao/projetos',
    component: ListPublicacaoProjetosComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/projetos/criar',
    component: PublicacaoProjetosComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/projetos/info/:id',
    component: InfoPublicacaoProjetosComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/projetos/log/:id',
    component: LogPublicacaoProjetosComponent,
    canActivate: [NotModService]
  },
  {
    path: 'integracao/criacaosrvintegracao/criar',
    component: CriacaoSrvIntegracaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'integracao/criacaosrvintegracao',
    component: ListSrvIntegracaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'integracao/criacaosrvintegracao/info/:id',
    component: InfoCriacaoSrvIntegracaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'integracao/criacaosrvintegracao/log/:id',
    component: LogCriacaoSrvIntegracaoComponent,
    canActivate: [NotModService]
  },
  {
    path: 'versao/cadastro',
    component: RegisterVersionComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/versao',
    component: NewListVersionsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'versao/editar/:id',
    component: EditVersionsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'integracao/tipodeintegracao/editar/:id',
    component: EditTypeIntegrationsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'integracao/tipodeintegracao/cadastro',
    component: RegisterTypeintegrationsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/tipodeintegracao',
    component: NewListTypeIntegrationsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'integracao/modulodeintegracao/cadastro',
    component: RegisterModuleintegrationsComponent,
    canActivate: [NotModService]
  },

  {
    path: 'integracao/modulodeintegracao/editar/:id',
    component: EditModuleintegrationsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/modulodeintegracao',
    component: NewListModuleintegrationsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'ambiente/criacao/producao',
    component: ListAmbienteProducaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/criacao/producao/criar',
    component: AmbienteProducaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/criacao/producao/info/:id',
    component: InfoAmbienteProducaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/criacao/producao/log/:id',
    component: LogAmbienteProducaoComponent,
    canActivate: [NotModService]
  },

  {
    path: 'versao/atualizacao',
    component: ListAtualizacaoVersaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'versao/atualizacao/criar',
    component: AtualizacaoVersaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'versao/atualizacao/info/:id',
    component: InfoAtualizacaoVersaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'versao/atualizacao/log/:id',
    component: LogAtualizacaoVersaoComponent,
    canActivate: [NotModService]
  },

  {
    path: 'ambiente/criacao/connect',
    component: ListAmbienteConnectComponent,
    canActivate: [NotVisualizacaoService]
  },
  {
    path: 'ambiente/criacao/connect/criar',
    component: AmbienteConnectComponent,
    canActivate: [NotVisualizacaoService]
  },
  {
    path: 'ambiente/criacao/connect/info/:id',
    component: InforAmbienteConnectComponent,
    canActivate: [NotVisualizacaoService]
  },
  {
    path: 'ambiente/criacao/connect/log/:id',
    component: LogAmbienteConnectComponent,
    canActivate: [NotModService]
  },

  {
    path: 'ambiente/criacao/homologacao',
    component: ListAmbienteHomologacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/criacao/homologacao/criar',
    component: AmbienteHomologacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/criacao/homologacao/info/:id',
    component: InfoAmbienteHomologacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/criacao/homologacao/log/:id',
    component: LogAmbienteHomologacaoComponent,
    canActivate: [NotModService]
  },
  {
    path: 'connect/produtos/cadastro',
    component: RegisterProdutoConnectComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/produtosconnect',
    component: NewListProdutoConnectComponent,
    canActivate: [NotModService]
  },
  {
    path: 'connect/produtos/editar/:id',
    component: EditProdutoConnectComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/clientes',
    component: NewListClientComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/clientes/cadastrar',
    component: RegisterClientComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/clientes/editar/:id',
    component: EditClientComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/linkclientes',
    component: NewListClientLinksComponent,
    canActivate: [NotModService]
  },

  {
    path: 'cadastros/linkclientes/cadastrar',
    component: RegisterClientLinksComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/linkclientes/editar/:id',
    component: EditClientLinksComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/usuarios',
    component: NewListUsersComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/usuarios/cadastrar',
    component: RegisterUsersComponent,
    canActivate: [NotModService]
  },
  {
    path: 'cadastros/usuarios/editar/:id',
    component: EditUsersComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'relatorios/logsistema',
    component: ListLogsSistemaComponent,
    canActivate: [NotModService]
  },
  {
    path: 'relatorios/url',
    component: ReportClientLinksComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'perfil',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'perfil/alterar-senha/:id',
    component: ChangePasswordComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'permissaonegada',
    component: PermissionDeniedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'acao-manual',
    component: AcaoManualComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/exclusao/executar',
    component: ExclusaoAmbienteComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'ambiente/exclusao',
    component: ListExclusaoAmbienteComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'acesso-base',
    component: AcessoBaseComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'relatorios/tamanho-nuvem',
    component: NewListTamanhoNuvemComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/agendamento-publicacao/criar',
    component: NewAgendamentoPublicacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'publicacao/agendamento-publicacao',
    component: ListAgendamentoPublicacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'autorizacoes/agendamento-publicacao',
    component: AllowAgendamentoPublicacaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'agendamento/backup/criar',
    component: NewAgendamentoBackupComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'agendamento/backup',
    component: ListAgendamentoBackupComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'relatorios/custos',
    component: CustosAwsComponent,
    canActivate: [NotModService]
  },
  {
    path: 'relatorios/custosEbs',
    component: CustoVolumesComponent,
    canActivate: [NotModService]
  },
  {
    path: 'relatorios/versaoPorBase',
    component: RelatorioBaseVersaoComponent,
    canActivate: [UsuarioService]
  },
  {
    path: 'relatorios/ambientesOneshare',
    component: RelatorioAmbientesOneshareComponent,
    canActivate: [NotVisualizacaoService]
  },
  {
    path: 'relatorios/baseInfoUso',
    component: RelatorioBaseInfoUsoComponent,
    canActivate: [UsuarioService]
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]

})
export class AppRoutingModule { }
