import { Component, OnInit } from '@angular/core';
import { LogsSistemaService } from '../logs-sistema.service';
import { SystemLogModel } from 'src/app/models/system-log';
import { SelectItem } from 'primeng/api/selectitem';
import * as XLSX from 'xlsx'; 
import {formatDate} from '@angular/common';


@Component({
  selector: 'app-list-logs-sistema',
  templateUrl: './list-logs-sistema.component.html',
  styleUrls: ['./list-logs-sistema.component.css']
})
export class ListLogsSistemaComponent implements OnInit {

  data: SystemLogModel[]
  fileName= formatDate(new Date(),'dd/MM/yyyy', 'en') + 'SystemLogs.xlsx';
  myDate = new Date();
  cols: any[]
  loading=true
  
  constructor(
    private logsSistemaService: LogsSistemaService
  ) { }

  ngOnInit() {
    this.logsSistemaService.getAll().subscribe(data => {
      this.data = data
      this.loading=false
    })

    this.cols = [
      { field: 'createdAt', header: 'Data' },
      { field: 'pat', header: 'Pat' },
      { field: 'client', header: 'Cliente' },
      { field: 'user', header: 'Usuário' },
      { field: 'environment', header:'Ambiente'},
      { field: 'action', header: 'Ação' },
      { field: 'approver', header: 'Aprovador' },
      { field: 'result', header: 'Resultado' },
      { field: 'obs', header: 'Obs'}
    ]

  }

  exportexcel(){
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}
