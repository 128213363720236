import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Http } from '@angular/http';
import { AtualizacaoVersaoService } from './atualizacao-versao.service';
import { map } from 'rxjs/operators';
import { VersionsService } from 'src/app/shared/register-version/versions.service';
import { Location } from '@angular/common';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-atualizacao-versao',
  templateUrl: './atualizacao-versao.component.html',
  styleUrls: ['./atualizacao-versao.component.css']
})
export class AtualizacaoVersaoComponent implements OnInit {

  versoes: any
  form: FormGroup
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any

  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private http: Http,
    private auth: AuthenticationService,
    private avs: AtualizacaoVersaoService,
    private versionservice: VersionsService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      urlAtualizacao: [null, Validators.required],
      versaoAntiga: [null, Validators.required],
      versaoNova: [null, Validators.required],
      cliente: [null, Validators.required],
      aprovador: [null],
      pat: [null, Validators.required],
      loggeduser: [null, Validators.required]

    })

    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )

    this.versionservice.getall().subscribe(versions => {
      this.versoes = []
      versions.forEach(version => {
        this.versoes.push({ versao: version.versao })
      })
    })
  }
  aplicaCssErro(campo) {
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo) {
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }

  onSubmit() {
    if (this.form.valid) {
      this.avs.post(this.form.value).pipe(map(res => res)).subscribe(dados => {
        this.form.get('urlAtualizacao').setValue(null)
        this.form.get('urlAtualizacao').markAsUntouched();
        this.form.get('versaoAntiga').setValue(null)
        this.form.get('versaoAntiga').markAsUntouched();
        this.form.get('versaoNova').setValue(null)
        this.form.get('versaoNova').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      },
        (error: any) => {
          this.modalText = error.message
          this.displayModal = true
        })
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  onBack() {
    this.location.back()
  }
}
