import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from 'src/app/shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class RelatorioBaseVersaoService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getData(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/commons/clientsversion`, httpOptions)
  }
}
