import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-register-users',
  templateUrl: './register-users.component.html',
  styleUrls: ['./register-users.component.css']
})
export class RegisterUsersComponent implements OnInit {

  form: FormGroup;
  perfils: any

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private router: Router, private location: Location, private route: ActivatedRoute) { }
  ngOnInit() {
    this.form = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      profile: ['admin', [Validators.required]],
    });
    this.perfils = [
      { name: 'Administrador', value: 'admin' },
      { name: 'Visualização', value: 'visualizacao' },
      { name: 'Usuário', value: 'usuario' },
      { name: 'OneShareBuilder', value: 'onesharebuilder' },
    ]
  }

  onSubmit() {
    if (this.form.valid) {
      this.userService.create(this.form.value).subscribe(success => this.location.back(), error => alert(error.error.error));
    }
    else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo);
        controle.markAsTouched();
      });
    }
  }
  onBack() {
    this.location.back();
  }
}
