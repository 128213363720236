import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { PublicacaoProjetosService } from './publicacao-projetos.service';
import { Location } from '@angular/common';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-publicacao-projetos',
  templateUrl: './publicacao-projetos.component.html',
  styleUrls: ['./publicacao-projetos.component.css']
})
export class PublicacaoProjetosComponent implements OnInit {

  form: FormGroup
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any
  
  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private http: Http,
    private auth: AuthenticationService,
    private pub: PublicacaoProjetosService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      urlDownload: [null, Validators.required],
      empresa:[null, Validators.required],
      cliente:[null, Validators.required],
      aprovador:[null],
      pat:[null, Validators.required],
      loggeduser:[null, Validators.required]
    })

    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )
  }

  onSubmit(){
    if(this.form.valid){
      this.pub.post(this.form.value).pipe(map(res=> res)).subscribe(dados=>{
        this.form.get('urlDownload').setValue(null)
        this.form.get('urlDownload').markAsUntouched();
        this.form.get('empresa').setValue(null)
        this.form.get('empresa').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      }, 
      (error: any)=>{
        this.modalText = error.message
        this.displayModal = true
      })   
    } else{
      Object.keys(this.form.controls).forEach((campo)=> {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }
  onBack(){
    this.location.back()
  }
}
