import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { TypeintegrationsService } from 'src/app/shared/register-typeintegrations/typeintegrations.service';
import { ModuleintegrationsService } from 'src/app/shared/register-moduleintegrations/moduleintegrations.service';
import { CriacaoSrvIntegracaoService } from './criacao-srv-integracao.service';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-criacao-srv-integracao',
  templateUrl: './criacao-srv-integracao.component.html',
  styleUrls: ['./criacao-srv-integracao.component.css']
})
export class CriacaoSrvIntegracaoComponent implements OnInit {

  form: FormGroup
  tipos: any
  ambientes: any
  modulos: any
  loggeduser: UserDetails
  displayModal: boolean
  modalText: any
  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private http: Http,
    private typeIntegrationService: TypeintegrationsService,
    private moduleIntegrationService: ModuleintegrationsService,
    private srvInt: CriacaoSrvIntegracaoService,
    private auth: AuthenticationService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      usuario: [null, Validators.required],
      moduloIntegracao: [null, Validators.required],
      tipoIntegracao: [null, Validators.required],
      ambiente:[null, Validators.required],
      cliente:[null, Validators.required],
      loggeduser:[null, Validators.required],
      aprovador:[null],
      pat:[null,Validators.required]
    })

    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )

    this.moduleIntegrationService.getAll().subscribe(modulos => {
      this.modulos = []
      modulos.forEach(modulo => {
        this.modulos.push({ nome: modulo.name })
      })
    })

    this.typeIntegrationService.getAll().subscribe(tipos => {
      this.tipos = []
      tipos.forEach(tipo => {
        this.tipos.push({ nome: tipo.name })
      })
    })

    this.ambientes = [
      { name: 'Produção', value: 'producao' },
      { name: 'Homologação', value: 'homologacao' },
      { name: 'Projetos', value: 'projetos' },
    ]
  }


  onSubmit(){
    if(this.form.valid){

      this.srvInt.post(this.form.value).pipe(map(res=> res)).subscribe(dados=>{
        this.form.get('usuario').setValue(null)
        this.form.get('usuario').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      }, 
      (error: any)=> {
        this.modalText = error.message
        this.displayModal = true
      })
      
    } else{
      Object.keys(this.form.controls).forEach((campo)=> {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  onBack(){
    this.location.back()
  }

}
