import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from '../global-constants';
import { TypeIntegrationModel } from 'src/app/models/type-integration';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypeintegrationsService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getAll() {
    return this.http.get<TypeIntegrationModel[]>(`${GlobalConstants.apiURL}/integrations/typeintegrations`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public create(type: TypeIntegrationModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.post(`${GlobalConstants.apiURL}/integrations/typeintegration/create`, type, httpOptions).pipe(take(1))
  }

  public disable(id: number){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.put(`${GlobalConstants.apiURL}/integrations/typeintegrations/disable/${id}`,obj , httpOptions).pipe(take(1))
  }

  public update(id: number, obj: TypeIntegrationModel){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    return this.http.put(`${GlobalConstants.apiURL}/integrations/typeintegrations/edit/${id}`,obj , httpOptions).pipe(take(1))
  }
 
  public delete(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.delete(`${GlobalConstants.apiURL}/integrations/typeintegrations/disable/${id}`, httpOptions).pipe(take(1))
  }
}
