import { Component, OnInit } from '@angular/core';
import { ModuleintegrationsService } from 'src/app/shared/register-moduleintegrations/moduleintegrations.service';
import { ModuleIntegrationModel } from 'src/app/models/module-integration';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-moduleintegrations',
  templateUrl: './list-moduleintegrations.component.html',
  styleUrls: ['./list-moduleintegrations.component.css']
})
export class ListModuleintegrationsComponent implements OnInit {
  items = []
  modulos = []
  pageOfItems: Array<any>

  constructor(private moduleIntegrationService: ModuleintegrationsService,
    private router: Router ) { }

  ngOnInit() {
    this.moduleIntegrationService.getAll().subscribe(dados => {
      dados.forEach((x)=>{
        if(x.disabled===0){
          this.modulos.push(x)
        }
      })
      this.modulos = this.modulos.sort((a,b) =>(a.name > b.name)? 1: -1)
      this.items = Array(0);
      for (let i = 0; i < this.modulos.length; i++) {
        this.items.push(this.modulos[i])
      }
      
    })
    
  }

  onEdit(id){
    this.router.navigate(['integracao/modulodeintegracao/editar', id])
  }

  onDelete(id){
    this.moduleIntegrationService.disable(id).subscribe(dados =>{
      console.log(dados)
    })
    location.reload()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

}
