import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AtualizacaoVersaoService } from '../atualizacao-versao.service';

@Component({
  selector: 'app-log-atualizacao-versao',
  templateUrl: './log-atualizacao-versao.component.html',
  styleUrls: ['./log-atualizacao-versao.component.css']
})
export class LogAtualizacaoVersaoComponent implements OnInit {

  log: string
  lines: string
  id: number
  
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private atualizacaoVersaoService: AtualizacaoVersaoService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.atualizacaoVersaoService.getLogById(this.id).subscribe((dados)=>{
      // this.log = dados
      let obj = JSON.parse(dados)
      this.lines= obj.log.split("\n")
      
    })
  }

  onBack(){
    this.location.back()
  }

 
}
