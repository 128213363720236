import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { AcessoBaseModel } from 'src/app/models/acesso-base';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AcessoBaseService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(ab: AcessoBaseModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })
    return this.http.post(`${GlobalConstants.apiURL}/executions/base-acess`, ab, { headers, responseType: 'text' }).pipe(take(1))
  }

  public getAll() {
    return this.http.get<AcessoBaseModel[]>(`${GlobalConstants.apiURL}/enviroments/deletes`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
}
