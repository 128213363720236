import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { VersionModel } from 'src/app/models/version';
import { VersionsService } from 'src/app/shared/register-version/versions.service';

@Component({
  selector: 'app-new-list-versions',
  templateUrl: './new-list-versions.component.html',
  styleUrls: ['./new-list-versions.component.css']
})
export class NewListVersionsComponent implements OnInit {

  multiSortMeta = []
  data: VersionModel[]
  myDate = new Date();
  
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading = true;

  constructor(private versionsService: VersionsService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {

    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.versionsService.getall().subscribe(data => {
      this.data = data
      this.loading = false;
    })


  }

  onDelete({dataItem}) {
    if(this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja excluir??',
        header: 'Remover',
        accept: () => {
          this.versionsService.delete(dataItem.id).subscribe((result) => {
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
    

  }

  onEdit({dataItem}) {
    this.router.navigate(['versao/editar', dataItem.id])
  }
}
