import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { SharedModule} from  './shared/shared.module'

import { AppComponent } from './app.component'
import { AuthenticationService } from './authentication.service'
import { AuthGuardService } from './auth-guard.service';
import { AppRoutingModule } from './app-routing.module'
import { ComponentsModule } from './components/components.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfirmationService} from 'primeng/api';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SplitterModule } from '@progress/kendo-angular-layout';








@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    GridModule,
    ChartsModule,
    TreeListModule,
    LayoutModule,
    SplitterModule
   
  ],
  providers: [AuthenticationService, AuthGuardService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule {}
