import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../client.service';
@Component({
    selector: 'app-register-client',
    templateUrl: './register-client.component.html',
    styleUrls: ['./register-client.component.css']
})
export class RegisterClientComponent implements OnInit {
    form: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        private clientService: ClientService,
        private router: Router, private location: Location, private route: ActivatedRoute) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            name: [null, [Validators.required]]
        });
    }
    
    onSubmit() {
        if (this.form.valid) {
            this.clientService.create(this.form.value).subscribe(success => this.location.back(), error => alert(error.error.error));
        }
        else {
            Object.keys(this.form.controls).forEach((campo) => {
                const controle = this.form.get(campo);
                controle.markAsTouched();
            });
        }
    }
    onBack() {
        this.location.back();
    }
}
