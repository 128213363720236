import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { AcessoBaseService } from './acesso-base.service';
import { ClientService } from '../client/client.service';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  selector: 'app-acesso-base',
  templateUrl: './acesso-base.component.html',
  styleUrls: ['./acesso-base.component.css']
})
export class AcessoBaseComponent implements OnInit {

  selectedFiles: FileList;
  form: FormGroup
  loggeduser: UserDetails
  displayModal: boolean
  mostrar: boolean = false
  uploadedFiles: any[] = [];
  clients: any
  modalText: any
  ambientes: any
  clientAuthorization: any

  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private auth: AuthenticationService,
    private amb: AcessoBaseService,
    private clientService: ClientService,
    private cd: ChangeDetectorRef,
    private uploadService: UploadService

  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      ambiente: [null, Validators.required],
      cliente: [null, Validators.required],
      aprovador: [null],
      pat: [null, Validators.required],
      loggeduser: [null, Validators.required],
      clientAuthorization: [null, Validators.required]
    })

    this.clientAuthorization = [
      { name: 'Sim', value: '1' },
      { name: 'Não', value: '0' },
    ]


    this.ambientes = [
      { name: 'Produção', value: 'producao' },
      { name: 'Homologação', value: 'homologacao' },
      { name: 'Projetos', value: 'projetos' },
    ]

    this.clientService.getAll().subscribe(clients => {
      this.clients = []
      clients.forEach(client => {
        this.clients.push({ name: client.name, id: client.id })
      })
    })

    this.auth.profile().subscribe(
      user => {
        this.loggeduser = user
        this.form.get('loggeduser').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )


  }


  onSubmit() {
    if (this.form.valid) {
      // //this.http.post('https://httpbin.org/post', JSON.stringify(this.form.value)).pipe(map(res => res)).subscribe(dados => console.log(dados))
      this.amb.post(this.form.value).pipe(map(res => res)).subscribe(() => {
        this.upload()
        this.form.get('ambiente').setValue(null)
        this.form.get('ambiente').markAsUntouched();
        this.form.get('clientAuthorization').setValue('1')
        this.form.get('clientAuthorization').markAsUntouched();
        this.form.get('cliente').setValue(null)
        this.form.get('cliente').markAsUntouched();
        this.form.get('aprovador').setValue(null)
        this.form.get('aprovador').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.modalText = "Requisição enviada"
        this.displayModal = true
      },
        (error: any) => {
          this.modalText = error.message
          this.displayModal = true
        })
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
  }

  trocar() {

    if (this.form.get('clientAuthorization').value.value == '1') {
      this.mostrar = true
    } else {
      this.mostrar = false
    }
  }

  upload() {
    const file = this.selectedFiles.item(0);
    this.uploadService.uploadFile(file, this.form.get('pat').value);
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }


  onBack() {
    this.location.back()
  }

}
