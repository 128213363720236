import { Component, OnInit } from '@angular/core';
import { ClienteLinkModel } from 'src/app/models/cliente-link';
import { ClientLinkService } from '../client-link.service';
import { TreeNode } from 'primeng/api';
import { ClientService } from '../../client/client.service';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-report-client-links',
  templateUrl: './report-client-links.component.html',
  styleUrls: ['./report-client-links.component.css']
})
export class ReportClientLinksComponent implements OnInit {

  dataTreeNodel: TreeNode[];
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + 'ClientsUrls.xlsx';

  data: any;
  dataExcel: any;
  cols: any[];

  collumns: any[]
  constructor(
    private clientLinkService: ClientLinkService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientService.getTreeTable().then(data => {
      this.data = data
      this.dataTreeNodel = this.data.data
    });

    this.clientLinkService.getAll().subscribe(data => {
      this.dataExcel = data
    })

    this.cols = [
      { field: 'name', header: 'Cliente' },
      { field: 'enviroment', header: 'Ambiente' }
    ];

    this.collumns = [
      { field: 'client', header: 'Cliente' },
      { field: 'url', header: 'URL' },
      { field: 'enviroment', header: 'Ambiente' }
    ]
  }

  exportexcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  public fetchChildren = (item) => {
    return item.contents;
  }
  public hasChildren = (item) => {
    return item.contents && item.contents.length > 0;
  }
}

