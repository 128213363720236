import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import { ClienteLinkModel } from 'src/app/models/cliente-link';
import { ClientLinkService } from '../client-link.service';
import { Location, formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-new-list-client-links',
  templateUrl: './new-list-client-links.component.html',
  styleUrls: ['./new-list-client-links.component.css']
})
export class NewListClientLinksComponent implements OnInit {
  multiSortMeta = []
  data: ClienteLinkModel[]
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  view: Observable<GridDataResult>;
  bindingType: String = 'array';
  loading=true
  constructor(
    private clientLinkService: ClientLinkService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router,
    ) {}

  ngOnInit(): void {

    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.clientLinkService.getAll().subscribe(data => {
      this.data = data
      this.loading = false
    })
    
    // this.cols = [
    //   { field: 'client', subfield: 'name', header: 'Cliente' },
    //   { field: 'url', header: 'URL' },
    //   { field: 'enviroment', header: 'Ambiente' },
    //   { field: 'createdAt', header: 'Data Criação' },
    //   { field: 'updatedAt', header: 'Última Atualização' },

    // ]

  }

  onDelete({dataItem}) {
    if(this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja excluir??',
        header: 'Excluir',
        accept: () => {
          this.clientLinkService.delete(dataItem.id).subscribe((result) => {
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
    
    
  }

  onEdit({dataItem}) {
    this.router.navigate(['cadastros/linkclientes/editar',dataItem.id])
  }


}
