import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { ClienteModel } from 'src/app/models/cliente';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UsuarioModel } from 'src/app/models/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getAll() {
    return this.http.get<UsuarioModel[]>(`${GlobalConstants.apiURL}/users/`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public create(user: UsuarioModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.post(`${GlobalConstants.apiURL}/users/create`, user, httpOptions).pipe(take(1))
  }

  public disable(id: number){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.put(`${GlobalConstants.apiURL}/users/disable/${id}`,obj , httpOptions).pipe(take(1))
  }
 
  public update(id: number, obj: UsuarioModel){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    return this.http.put(`${GlobalConstants.apiURL}/users/edit/${id}`,obj , httpOptions).pipe(take(1))
  }

  public get(id: number) {
    return this.http.get<UsuarioModel[]>(`${GlobalConstants.apiURL}/users/${id}`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public delete(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.delete(`${GlobalConstants.apiURL}/users/disable/${id}`, httpOptions).pipe(take(1))
  }
}
