import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { UsuarioModel } from 'src/app/models/usuario';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-new-list-users',
  templateUrl: './new-list-users.component.html',
  styleUrls: ['./new-list-users.component.css']
})
export class NewListUsersComponent implements OnInit {

  multiSortMeta = []
  data: UsuarioModel[]
  myDate = new Date();
  
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading = true;
  constructor(private usersService: UsersService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {

    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.usersService.getAll().subscribe(data => {
      this.data = data
      this.loading = false;
    })

    

  }

  onDelete({dataItem}) {
    if(this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja excluir??',
        header: 'Remover',
        accept: () => {
          this.usersService.delete(dataItem.id).subscribe((result) => {
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
    

  }

  onEdit({dataItem}) {
    this.router.navigate(['cadastros/usuarios/editar', dataItem.id])
  }
}
 