import { keyframes } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceQuotas } from "aws-sdk/clients/all";
import { CustosAwsService } from "./custos-aws.service";

@Component({
  selector: "app-custos-aws",
  templateUrl: "./custos-aws.component.html",
  styleUrls: ["./custos-aws.component.css"],
})
export class CustosAwsComponent implements OnInit {
  custoByService: any;
  dataByService: any;
  custo: any;
  previsao: any;
  data: any;
  total: any;
  custoCard: any;
  previsaoCard: any;
  services: any[] = [];
  rangeDates: Date[];
  today = new Date();
  monthSelected: any;
  months: any[];
  datasGrafico: any[];
  resultadosTabela: any[] = []
  percent={style: '', value:''}
  minDate: any = new Date(2020, 9, 1, 0, 0, 0, 0);
  dataKendo: any = []
  lastMonthCost: any
  
  constructor(
    private custosAwsService: CustosAwsService,
    private router: Router
  ) { }
  cols: any[] = [];

  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  dataSelecionada() {
    this.custosAwsService.postDate(this.rangeDates).subscribe((dados) => {
      let labels = [];
      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      let months = [];
      let monthsEditted = [];
      let day = 0;
      let grupos = [];
      let indexMaiores = [];
      let indexMaior;
      let amountsByMonth = [];
      let amounts = [];
      let labelsByMonth = [];
      let dataByObject = [];
      let dataByObjectByMonth = [];
      let amountsArray = [];
      let amountsArrayByMonth = [];
      let labelsArray = [];
      let labelsArrayByMonth = [];
      let labelsMaiores = [];
      let labelsMaioresByMonth = [];
      let amountsMaiores = [];
      let amountsMaioresByMonth = [];
      let repeticoesArray = [];
      let labelsGrafico = [];
      let barras = [];


      this.custoByService = dados;
      months = this.custoByService.ResultsByTime;

      for (let index = 0; index < months.length; index++) {
        let fullDay = months[index].TimePeriod.Start.split("-");
        day = fullDay[1] - 1;
        monthsEditted.push(monthNames[day]);
        grupos = months[index].Groups;
        for (let i = 0; i < grupos.length; i++) {
          labelsByMonth.push(grupos[i].Keys[0]);
          amountsByMonth.push(grupos[i].Metrics.UnblendedCost.Amount);
        }
        amounts.push(amountsByMonth);
        labels.push(labelsByMonth);
        labelsByMonth = [];
        amountsByMonth = [];
      }
      for (let index = 0; index < labels.length; index++) {
        for (let i = 0; i < labels[index].length; i++) {
          dataByObjectByMonth.push({
            name: labels[index][i],
            amount: parseFloat(parseFloat(amounts[index][i]).toFixed(2)),
          });
        }
        dataByObject.push(dataByObjectByMonth);
        dataByObjectByMonth = [];
      }
      this.datasGrafico = dataByObject;
      for (let index = 0; index < dataByObject.length; index++) {
        for (let i = 0; i < dataByObject[index].length; i++) {
          amountsArrayByMonth.push(dataByObject[index][i].amount);
          labelsArrayByMonth.push(dataByObject[index][i].name);
        }
        amountsArray.push(amountsArrayByMonth);
        labelsArray.push(labelsArrayByMonth);
        amountsArrayByMonth = [];
        labelsArrayByMonth = [];
        indexMaiores.push([]);
      }
      let amountsFinal = [];
      for (let index = 0; index < amountsArray.length; index++) {
        amountsFinal.push(amountsArray[index]);
      }

      for (let index = 0; index < amountsArray.length; index++) {
        for (let i = 0; i < 5; i++) {
          indexMaior = amountsArray[index].indexOf(
            Math.max(...amountsArray[index])
          );
          indexMaiores[index].push(
            amountsFinal[index].indexOf(amountsArray[index][indexMaior])
          );
          if (amountsArray[index][indexMaior] != 0) {
            amountsArray[index] = amountsArray[index].filter(
              (x) => x !== amountsArray[index][indexMaior]
            );
          }
        }
      }

      for (let index = 0; index < indexMaiores.length; index++) {
        for (let i = 0; i < indexMaiores[index].length; i++) {
          labelsMaioresByMonth.push(labelsArray[index][indexMaiores[index][i]]);
        }
        labelsMaiores.push(labelsMaioresByMonth);
        labelsMaioresByMonth = [];
      }

      for (let index = 0; index < labelsMaiores.length; index++) {
        let repeticoes = 0;
        for (let i = 0; i < labelsMaiores.length; i++) {
          if (i != index) {
            if (
              JSON.stringify(labelsMaiores[i]) ===
              JSON.stringify(labelsMaiores[index])
            ) {
              if (typeof labelsMaiores[i][0] !== "undefined") {
                repeticoes += 1;
              }
            }
          }
        }
        repeticoesArray.push(repeticoes);
      }

      let indexRepeticao = repeticoesArray.indexOf(
        Math.max(...repeticoesArray)
      );
      labelsGrafico = labelsMaiores[indexRepeticao];

      let indexArrayMaiores = [];
      let indexArrayMaioresByMonth = [];
      for (let index = 0; index < labelsArray.length; index++) {
        for (let i = 0; i < labelsArray[index].length; i++) {
          for (let k = 0; k < 5; k++) {
            if (labelsArray[index][i] === labelsGrafico[k]) {
              indexArrayMaioresByMonth.push(i);
            }
          }
        }
        indexArrayMaiores.push(indexArrayMaioresByMonth);
        indexArrayMaioresByMonth = [];
      }

      labelsGrafico.push("Outros");

      let soma = [];
      let somaByMonth;
      for (let index = 0; index < amountsFinal.length; index++) {
        somaByMonth = amountsFinal[index].reduce((a, b) => a + b, 0);
        soma.push(somaByMonth);
        somaByMonth = 0;
      }
      for (let index = 0; index < soma.length; index++) {
        for (let i = 0; i < indexArrayMaiores[index].length; i++) {
          soma[index] -= amountsFinal[index][indexArrayMaiores[index][i]];
        }
      }

      for (let index = 0; index < indexArrayMaiores.length; index++) {
        for (let i = 0; i < indexArrayMaiores[index].length; i++) {
          amountsMaioresByMonth.push(
            amountsFinal[index][indexArrayMaiores[index][i]]
          );
        }
        amountsMaiores.push(amountsMaioresByMonth);
        amountsMaioresByMonth = [];
      }

      for (let index = 0; index < amountsMaiores.length; index++) {
        if (soma[index] != 0) {
          amountsMaiores[index].push(parseFloat(soma[index].toFixed(2)));
        }
      }

      let dataOrganizado = [];
      let dataOrganizadoByMonth = [];
      for (let index = 0; index < indexArrayMaiores.length; index++) {
        for (let i = 0; i < indexArrayMaiores[index].length; i++) {
          dataOrganizadoByMonth.push({
            name: labels[index][indexArrayMaiores[index][i]],
            value: amountsMaiores[index][i],
          });
        }
        dataOrganizadoByMonth.push({
          name: "Outros",
          value: amountsMaiores[index][5],
        });
        dataOrganizado.push(dataOrganizadoByMonth);
        dataOrganizadoByMonth = [];
      }
      labelsGrafico = [];
      for (let index = 0; index < amountsMaiores[0].length; index++) {
        labelsGrafico.push(dataOrganizado[0][index].name);
      }

      let dataOrganizadoGrafico = [];
      let dataOrganizadoGraficoByMonth = [];
      for (let i = 0; i < labelsGrafico.length; i++) {
        for (let j = 0; j < monthsEditted.length; j++) {
          dataOrganizadoGraficoByMonth.push(amountsMaiores[j][i]);
        }
        dataOrganizadoGrafico.push(dataOrganizadoGraficoByMonth);
        dataOrganizadoGraficoByMonth = [];
      }

      for (let index = 0; index < labelsGrafico.length; index++) {
        barras.push({
          label: labelsGrafico[index],
          backgroundColor: this.getRandomColor(),
          data: dataOrganizadoGrafico[index],
        });
      }

      this.dataByService = {
        labels: monthsEditted,
        datasets: barras,
        options: {
          dataIndex: {
            display: true,
            position: "bottom",
          },
        },
      };
      this.months = [];


      let maiorIndex = 0;
      let maiorQtd = 0;
      for (let index = 0; index < labelsArray.length; index++) {
        const element = labelsArray[index];
        if (element.length >= maiorQtd) {
          maiorQtd = element.length;
          maiorIndex = index;
        }
      }

      this.cols = [{ field: "servicos", header: "Serviços" }];
      monthsEditted.forEach((element) => {
        this.cols.push({ field: element, header: element });
      });

      let differences = [];
      for (let index = 0; index < labelsArray.length; index++) {
        differences.push(
          labelsArray[maiorIndex].filter((x) => !labelsArray[index].includes(x))
        );
      }

      for (let index = 0; index < dataByObject.length; index++) {


      }


      let resultados = []
      let resultadosByMonth = []
      for (let i = 0; i < dataByObject[maiorIndex].length; i++) {
        for (let j = 0; j < this.cols.length; j++) {
          if (j == 0) {
            resultadosByMonth.push(labelsArray[maiorIndex][i])
          } else {
            if (typeof labelsArray[j - 1].indexOf(labelsArray[maiorIndex][i] != 'undefined')) {
              resultadosByMonth.push(amountsFinal[j - 1][labelsArray[j - 1].indexOf(labelsArray[maiorIndex][i])])
            }
          }
        }
        resultados.push(resultadosByMonth)
        resultadosByMonth = []
      }

      for (let i = 0; i < resultados.length; i++) {
        for (let j = 0; j < resultados[i].length; j++) {
          if (typeof resultados[i][j] === 'undefined') {
            resultados[i][j] = amountsFinal[j - 1][i]
          }
        }

      }
      this.services = []
      for (let i = 0; i < resultados.length; i++) {
        this.services.push({ servicos: resultados[i][0] })
      }

      for (let i = 0; i < this.services.length; i++) {
        for (let j = 0; j < resultados[i].length; j++) {
          if (j != 0) {
            let key = monthsEditted[j - 1]
            let pair = {}
            pair[key] = resultados[i][j]
            this.services[i] = { ...this.services[i], ...pair }
          }

        }
      }
      this.resultadosTabela = resultados
      this.dataKendo = this.dataByService.datasets
      

    });
  }

  ngOnInit(): void {

    this.custosAwsService.getMonthCost().subscribe((dados) => {
      this.custo = dados;

      this.custosAwsService.getCostForecast().subscribe((dados) => {
        this.previsao = dados;



        this.custosAwsService.getCostLastMonth().subscribe((dados) => {
          let data = dados
          this.total =
            parseFloat(this.custo.Total.Amount) +
            parseFloat(this.previsao.Total.Amount);
          this.data = {
            labels: ["Custo atual", "previsão extra"],
            datasets: [
              {
                label: "Custo e previsâo",
                backgroundColor: "#42A5F5",
                borderColor: "#1E88E5",
                data: [
                  parseFloat(this.custo.Total.Amount).toFixed(2),
                  this.total.toFixed(2),
                ],
              },
            ],
          };
          this.custoCard = parseFloat(this.custo.Total.Amount).toFixed(2);
          this.previsaoCard = this.total.toFixed(2);
          this.lastMonthCost = parseFloat(data['ResultsByTime'][0]['Total']['UnblendedCost']['Amount']).toFixed(2)
          let porcentagem = (( parseFloat(this.previsaoCard) * 100)/ parseFloat(this.lastMonthCost)).toFixed(2)
          console.log(porcentagem)
          if(parseFloat(porcentagem) >= 100){
            this.percent.style = 'positivo'
            this.percent.value =  (parseFloat(porcentagem) - 100).toFixed(2)
          } else {
            this.percent.style = 'negativo'
            this.percent.value =  (-((parseFloat(porcentagem) -100))).toFixed(2)
          }
          console.log(this.percent)

        })
      });
    });

    this.custosAwsService.getCostByService().subscribe((dados) => {
      this.custoByService = dados;
      let i = 0;
      let barras = [];
      let labels = [];
      let amounts = [];
      let amountsfloat = [];
      let indexMaiores = [];
      let indexMaior;
      let labelsMaiores = [];
      let amountsMaiores = [];
      while (this.custoByService.ResultsByTime[0].Groups[i]) {
        labels.push(this.custoByService.ResultsByTime[0].Groups[i].Keys[0]);
        amounts.push(
          parseFloat(
            this.custoByService.ResultsByTime[0].Groups[i].Metrics.UnblendedCost
              .Amount
          ).toFixed(2)
        );
        i++;
      }

      amountsfloat = amounts.map((x) => parseFloat(x));
      let amountsFinal = amounts.map((x) => parseFloat(x));

      for (let index = 0; index <= 4; index++) {
        indexMaior = amountsfloat.indexOf(Math.max(...amountsfloat));
        indexMaiores.push(amountsFinal.indexOf(amountsfloat[indexMaior]));
        if (amountsfloat[indexMaior] != 0) {
          amountsfloat = amountsfloat.filter(
            (x) => x !== amountsfloat[indexMaior]
          );
        }
      }

      for (let index = 0; index < indexMaiores.length; index++) {
        labelsMaiores.push(labels[indexMaiores[index]]);
        amountsMaiores.push(amountsFinal[indexMaiores[index]]);
      }

      labelsMaiores.push("Outros");
      let soma = amountsfloat.reduce((a, b) => a + b, 0);

      amountsMaiores.push(soma.toFixed(2));
      amountsMaiores = amountsMaiores.map((x) => parseFloat(x));


      for (let index = 0; index < labelsMaiores.length; index++) {
        barras.push({
          label: labelsMaiores[index],
          backgroundColor: this.getRandomColor(),
          data: [amountsMaiores[index]],
        });
      }
      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];

      const d = new Date();
      this.months = [{ name: monthNames[d.getMonth()] }];
      this.dataByService = {
        labels: [monthNames[d.getMonth()]],
        datasets: barras,
      };
      this.cols = [
        { field: "servicos", header: "Serviços" },
        { field: monthNames[d.getMonth()], header: monthNames[d.getMonth()] }
      ]

      for (let i = 0; i < amountsFinal.length; i++) {
        this.services.push({ servicos: labels[i] })
        this.services[i][monthNames[d.getMonth()]] = amountsFinal[i]
      }

      this.dataKendo = this.dataByService.datasets


    });
  }
}
