import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PublicacaoProjetosService } from '../publicacao-projetos.service';

@Component({
  selector: 'app-log-publicacao-projetos',
  templateUrl: './log-publicacao-projetos.component.html',
  styleUrls: ['./log-publicacao-projetos.component.css']
})
export class LogPublicacaoProjetosComponent implements OnInit {

  log: string
  lines: string
  id: number
  
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private publicacaoProjetosService: PublicacaoProjetosService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.publicacaoProjetosService.getLogById(this.id).subscribe((dados)=>{
      // this.log = dados
      let obj = JSON.parse(dados)
      this.lines= obj.log.split("\n")
      
    })
  }

  onBack(){
    this.location.back()
  }

}
