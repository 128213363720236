import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicacaoProjetosService } from '../publicacao-projetos.service';

@Component({
  selector: 'app-info-publicacao-projetos',
  templateUrl: './info-publicacao-projetos.component.html',
  styleUrls: ['./info-publicacao-projetos.component.css']
})
export class InfoPublicacaoProjetosComponent implements OnInit {

  parameters=[]
  id: number
  build
  date
  
  constructor(
    private route: ActivatedRoute,
    private publicacaoProjetosService: PublicacaoProjetosService,
    private location: Location,
    private router: Router
    
    ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.publicacaoProjetosService.getBuildsById(this.id).subscribe((dados)=>{
      this.build = dados
      this.parameters = this.build.actions[0].parameters
      this.date = new Date(dados.timestamp)
    })
  }

  onBack(){
    this.location.back()
    
  }
  onLog(){
    this.router.navigate(['publicacao/projetos/log', this.id])
  }
 
}
