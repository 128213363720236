import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { RelatorioBaseInfoUsoService } from './relatorio-base-info-uso.service';

@Component({
  selector: 'app-relatorio-base-info-uso',
  templateUrl: './relatorio-base-info-uso.component.html',
  styleUrls: ['./relatorio-base-info-uso.component.css']
})
export class RelatorioBaseInfoUsoComponent implements OnInit {
  loading = true;
  items = [];
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + '_Relatorio_Informacao_Bases.xlsx';

  public state: State = {
    skip: 0,
    take: 5,
  };
  public gridData: GridDataResult = process(this.items, this.state);

  constructor(
    private relatorioBaseInfoUsoService: RelatorioBaseInfoUsoService,
    private router: Router
  ) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.relatorioBaseInfoUsoService.getData().subscribe(dados => {
      this.items = dados
      this.loading = false;
      this.gridData = process(this.items, this.state);
    })


  }

  public allData(): ExcelExportData {
    var myState: State = this.state;
    myState.skip = 0;
    myState.take = this.gridData.total;
    const result: ExcelExportData = {
      data: process(this.items, this.state).data
    };
    return result;
  }


}
