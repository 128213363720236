import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientLinkService } from '../client-link.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-list-client-links',
  templateUrl: './list-client-links.component.html',
  styleUrls: ['./list-client-links.component.css']
})
export class ListClientLinksComponent implements OnInit {

  items = []
  clienteLinks = []
  filterClientLinks = []
  pageOfItems: Array<any>
  form: FormGroup
  fileName= 'ExcelSheet.xlsx';

  constructor(
    private clientService: ClientLinkService,
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      client: [null]
    });

    this.clientService.getAll().subscribe(dados => {
      dados.forEach((x) => {
        if (x.disabled === 0) {
          this.clienteLinks.push(x)
        }
      })
      this.clienteLinks = this.clienteLinks.sort((a, b) => {
        if (a.client.name.toUpperCase() < b.client.name.toUpperCase()) return -1;
        if (a.client.name.toUpperCase() > b.client.name.toUpperCase()) return 1;
        if (a.enviroment > b.enviroment) return 1;
        if (a.enviroment < b.enviroment) return -1;
      })
      this.items = Array(0);
      for (let i = 0; i < this.clienteLinks.length; i++) {
        this.items.push(this.clienteLinks[i])
      }


    })

  }

  onEdit(id) {
    this.router.navigate(['cadastros/linkclientes/editar', id])
  }

  onDelete(id) {
    this.clientService.disable(id).subscribe(dados => {
      console.log(dados)
    })
    location.reload()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onFilter() {
    //console.log(this.form.get('client').value)
    this.filterClientLinks = []
    this.clienteLinks.forEach(element => {
      if (element.client.name.toUpperCase() == this.form.get('client').value.toUpperCase()) {
        this.filterClientLinks.push(element)
      }
    });

    this.filterClientLinks = this.filterClientLinks.sort((a, b) => {
      if (a.client.name.toUpperCase() < b.client.name.toUpperCase()) return -1;
      if (a.client.name.toUpperCase() > b.client.name.toUpperCase()) return 1;
      if (a.enviroment > b.enviroment) return 1;
      if (a.enviroment < b.enviroment) return -1;
    })

    this.items = [];
    for (let i = 0; i < this.filterClientLinks.length; i++) {
      this.items.push(this.filterClientLinks[i])
    }
  }

  onReset() {
    this.items = [];
    for (let i = 0; i < this.clienteLinks.length; i++) {
      this.items.push(this.clienteLinks[i])
    }
  }

}
