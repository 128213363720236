import { Component, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CriacaoSrvIntegracaoService } from '../criacao-srv-integracao.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-list-srv-integracao',
  templateUrl: './list-srv-integracao.component.html',
  styleUrls: ['./list-srv-integracao.component.css']
})
export class ListSrvIntegracaoComponent implements OnInit {

  items = []
  builds = []
  pageOfItems: Array<any>;
  loading=true;
  

  constructor(
    private criacaoSrvIntegracaoService: CriacaoSrvIntegracaoService,
    private router: Router) { }

  ngOnInit() {
    this.criacaoSrvIntegracaoService.getBuilds().subscribe(dados => {
      dados.forEach((x) => {
        this.builds.push(x)
      })
      this.builds = this.builds.sort((a, b) => (a.number < b.number) ? 1 : -1)
      this.items = Array(0);
      for (let i = 0; i < this.builds.length; i++) {
        this.items.push(this.builds[i])
      }
      this.loading=false;
      
    })

  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onInfo({dataItem}) {
    this.router.navigate(['integracao/criacaosrvintegracao/info', dataItem.number])
  }

}
 