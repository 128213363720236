import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TypeintegrationsService } from 'src/app/shared/register-typeintegrations/typeintegrations.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-type-integrations',
  templateUrl: './edit-type-integrations.component.html',
  styleUrls: ['./edit-type-integrations.component.css']
})
export class EditTypeIntegrationsComponent implements OnInit {

  id: number
  form: FormGroup

  constructor(private formBuilder: FormBuilder,
    private typeIntegrationService: TypeintegrationsService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]]
    })
  }

  aplicaCssErro(campo) {
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo) {
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }

  onSubmit() {

    if (this.form.valid) {
      this.typeIntegrationService.update(this.id, this.form.value).pipe(map(res => res)).subscribe(dados=>{
        this.location.back()
      },
      (error:any)=> alert(error.message))
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }

  }

  onBack() {
    this.location.back()
  }
}
