import { Injectable } from '@angular/core';
import { TamanhoNuvemModel } from 'src/app/models/tamanho-nuvem';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TamanhoNuvemService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getall() {
    return this.http.get<TamanhoNuvemModel[]>(`${GlobalConstants.apiURL}/commons/cloudsizes`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }
  public gettree() {
    return this.http.get<TamanhoNuvemModel[]>(`${GlobalConstants.apiURL}/commons/cloudsizestreetable`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    }).toPromise()
  }

  public getGraphInfo(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    }; 

    return this.http.post(`${GlobalConstants.apiURL}/commons/coudsizes/graphInfo`, data, httpOptions).pipe(take(1))
  }
}
