import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent} from './menu/menu.component'
import { AppRoutingModule } from '../app-routing.module';
import { FormDebugComponent } from './form-debug/form-debug.component';
import { RegisterVersionComponent } from './register-version/register-version.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CampoControlErrorComponent } from './campo-control-error/campo-control-error.component';
import { RegisterTypeintegrationsComponent } from './register-typeintegrations/register-typeintegrations.component';
import { RegisterModuleintegrationsComponent } from './register-moduleintegrations/register-moduleintegrations.component';
import { RegisterProdutoConnectComponent } from './register-produto-connect/register-produto-connect.component';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';

import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CardModule} from 'primeng/card';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    CardModule
  ],
  declarations: [
    MenuComponent,
    FormDebugComponent,
    RegisterVersionComponent,
    CampoControlErrorComponent,
    RegisterTypeintegrationsComponent,
    RegisterModuleintegrationsComponent,
    RegisterProdutoConnectComponent,
    PermissionDeniedComponent,
    
  ],
  exports: [
    MenuComponent,
    FormDebugComponent,
    CampoControlErrorComponent
  ]
})
export class SharedModule { }
