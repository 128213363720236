import { Component, OnInit } from '@angular/core';
import { AgendamentoPublicacaoModel } from 'src/app/models/agendamento-publicacao';
import { formatDate } from '@angular/common';
import { AgendamentoPublicacaoService } from '../agendamento-publicacao.service';
import * as XLSX from 'xlsx';
import { AuthenticationService, UserDetails } from 'src/app/authentication.service';
import { ConfirmationService, Message } from 'primeng/api';
import { Location} from '@angular/common';

@Component({
  selector: 'app-list-agendamento-publicacao',
  templateUrl: './list-agendamento-publicacao.component.html',
  styleUrls: ['./list-agendamento-publicacao.component.css']
})
export class ListAgendamentoPublicacaoComponent implements OnInit {
  multiSortMeta = []
  data: AgendamentoPublicacaoModel[]
  fileName = formatDate(new Date(), 'dd/MM/yyyy', 'en') + 'SystemLogs.xlsx';
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading = true;

  constructor(
    private agendamentoPublicacaoService: AgendamentoPublicacaoService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'date', order: -1 });
    this.multiSortMeta.push({ field: 'time', order: 1 });
    let dia = []
    this.agendamentoPublicacaoService.getAll().subscribe(data => {
      data.forEach(element => {
        dia = element.date.split("/")
        element.date = new Date(dia[2], dia[1] -1, dia[0] ).toDateString()
      });
      this.data = data; 
      this.loading = false})

  }


  onDelete({dataItem}){
    if(dataItem.user == this.user.email || this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja cancelar esse agendamento?',
        header: 'Cancelamento de Agendamento',
        accept: () => {
          this.agendamentoPublicacaoService.delete(dataItem.id).subscribe((result)=>{
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
  }

  
}
