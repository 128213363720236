import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmbienteConnectService } from '../ambiente-connect.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-infor-ambiente-connect',
  templateUrl: './infor-ambiente-connect.component.html',
  styleUrls: ['./infor-ambiente-connect.component.css']
})
export class InforAmbienteConnectComponent implements OnInit {

  parameters=[]
  id: number
  build
  date
  
  constructor(
    private route: ActivatedRoute,
    private ambienteConnectService: AmbienteConnectService,
    private location: Location,
    private router: Router
    
    ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.ambienteConnectService.getBuildsById(this.id).subscribe((dados)=>{
      this.build = dados
      this.parameters = this.build.actions[0].parameters
      this.date = new Date(dados.timestamp)
    })
  }

  onBack(){
    this.location.back()
    
  }
  onLog(){
    this.router.navigate(['ambiente/criacao/connect/log', this.id])
  }
 

}
