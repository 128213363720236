import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { UsuarioModel } from 'src/app/models/usuario';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.css']
})
export class EditUsersComponent implements OnInit {

  id: number
  form: FormGroup
  user: any
  perfils: any
  selectedProfile: any

  constructor(private formBuilder: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })

    
    this.form = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      profile: ['admin', [Validators.required]],
    })
    this.perfils = [
      { name: 'Administrador', value: 'admin' },
      { name: 'Visualização', value: 'visualizacao' },
      { name: 'Usuário', value: 'usuario' },
      { name: 'OneShareBuilder', value: 'onesharebuilder' },
    ]

    this.userService.get(this.id).subscribe((dados) => {
      this.user = dados
      this.form.get('first_name').setValue(this.user.first_name)
      this.form.get('last_name').setValue(this.user.last_name)
      this.form.get('email').setValue(this.user.email)
      this.form.get('password').setValue(this.user.password)
      this.form.get('profile').setValue(this.user.profile)
      if(this.user.profile == "admin"){
        this.selectedProfile = {name: "Administrador", value: this.user.profile}
      } else if(this.user.profile == "visualizacao"){
        this.selectedProfile ={name: "Visualização", value: this.user.profile}
      } else if(this.user.profile == "usuario"){
        this.selectedProfile = {name:"Usuário", value: this.user.profile}
      } else if(this.user.profile == "onesharebuilder"){
        this.selectedProfile = {name:"OneShareBuilder",value:this.user.profile}
      }
      
      
    })
  }

  

  onSubmit() {

    if (this.form.valid) {
      this.userService.update(this.id, this.form.value).pipe(map(res => res)).subscribe(dados=>{
        this.location.back()
      },
      (error:any)=> alert(error.message))
    } else {
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }

  }

  onBack() {
    this.location.back()
  }
}
