import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {

  items = []
  usuarios = []
  pageOfItems: Array<any>

  constructor(
    private userService: UsersService,
    private router: Router ) { }

  ngOnInit() {
    this.userService.getAll().subscribe(dados => {
      
      dados.forEach((x)=>{
        if(x.disabled===0){
          this.usuarios.push(x)
        }
      })
      this.usuarios = this.usuarios.sort((a,b) =>(a.first_name.toUpperCase() > b.first_name.toUpperCase())? 1: -1)
      
      this.items = Array(0);
      for (let i = 0; i < this.usuarios.length; i++) {
        this.items.push(this.usuarios[i])
      }
      
    })
    
  }

  onEdit(id){
    this.router.navigate(['cadastros/usuarios/editar', id])
  }

  onDelete(id){
    this.userService.disable(id).subscribe(dados =>{
      console.log(dados)
    })
    location.reload()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

 
}
