import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../authentication.service';
import{ GlobalConstants } from '../global-constants';
import { VersionModel } from '../../models/version';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionsService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getall() {
    return this.http.get<VersionModel[]>(`${GlobalConstants.apiURL}/versions/`, {
      headers: { Authorization: ` ${this.auth.getToken()}` }
    })
  }

  public create(version: VersionModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.post(`${GlobalConstants.apiURL}/versions/create`, version, httpOptions).pipe(take(1))
  }

  public disable(id: number){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.put(`${GlobalConstants.apiURL}/versions/disable/${id}`,obj , httpOptions).pipe(take(1))
  }

  public update(id: number, obj: VersionModel){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    return this.http.put(`${GlobalConstants.apiURL}/versions/edit/${id}`,obj , httpOptions).pipe(take(1))
  }

  public delete(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    let obj= {}
    return this.http.delete(`${GlobalConstants.apiURL}/versions/disable/${id}`, httpOptions).pipe(take(1))
  }

}
