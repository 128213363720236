import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  id: number
  form: FormGroup
  user: any
  igual: boolean = true

  constructor(private formBuilder: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.params.subscribe((params: any) => {
      this.id = params['id']
    })


    this.form = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      repeatPassword: [null, [Validators.required]],
      profile: [null, [Validators.required]],
    })

    this.userService.get(this.id).subscribe((dados) => {
      this.user = dados
      this.form.get('first_name').setValue(this.user.first_name)
      this.form.get('last_name').setValue(this.user.last_name)
      this.form.get('email').setValue(this.user.email)
      this.form.get('profile').setValue(this.user.profile)
    })
  }

  aplicaCssErro(campo) {
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo) {
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }

  onSubmit() {
    if (this.form.get('password').value != this.form.get('repeatPassword').value) {
      this.igual = false
    } else {
      if (this.form.valid) {
        this.userService.update(this.id, this.form.value).pipe(map(res => res)).subscribe(dados => {
          this.location.back()
        },
          (error: any) => alert(error.message))
      } else {
        Object.keys(this.form.controls).forEach((campo) => {
          const controle = this.form.get(campo)
          controle.markAsTouched()
        })
      }
    }
  }

  onBack() {
    this.location.back()
  }

}
