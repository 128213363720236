import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UserDetails, AuthenticationService } from 'src/app/authentication.service';
import { ModuleIntegrationModel } from 'src/app/models/module-integration';
import { ModuleintegrationsService } from 'src/app/shared/register-moduleintegrations/moduleintegrations.service';

@Component({
  selector: 'app-new-list-moduleintegrations',
  templateUrl: './new-list-moduleintegrations.component.html',
  styleUrls: ['./new-list-moduleintegrations.component.css']
})
export class NewListModuleintegrationsComponent implements OnInit {

  multiSortMeta = []
  data: ModuleIntegrationModel[]
  myDate = new Date();
  cols: any[]
  user: UserDetails
  msgs: Message[] = [];
  display: boolean = false;
  loading = false;

  constructor(private moduleIntegrationService: ModuleintegrationsService,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private location: Location,
    private router: Router) { }

  ngOnInit(): void {

    this.user = this.auth.getUserDetails()
    this.multiSortMeta = [];
    this.multiSortMeta.push({ field: 'client', order: 1 });
    this.moduleIntegrationService.getAll().subscribe(data => {
      this.data = data
      this.loading = false;
    })

    this.cols = [
      { field: 'name', header: 'Nome' },
      { field: 'createdAt', header: 'Data Criação' },
      { field: 'updatedAt', header: 'Última Atualização' },

    ]

  }

  onDelete({dataItem}) {
    if(this.user.profile == 'admin'){
      this.confirmationService.confirm({
        message: 'Vocë tem certeza que deseja excluir??',
        header: 'Remover',
        accept: () => {
          this.moduleIntegrationService.delete(dataItem.id).subscribe((result) => {
            location.reload();
          })
        }
    });
    } else{
      this.display = true;
    }
    

  }

  onEdit({dataItem}) {
    this.router.navigate(['integracao/modulodeintegracao/editar', dataItem.id])
  }

}
