import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {HttpModule} from '@angular/http'
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { PublicacaoProducaoComponent } from './publicacao-producao/publicacao-producao.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PublicacaoProjetosComponent } from './publicacao-projetos/publicacao-projetos.component';
import { CriacaoSrvIntegracaoComponent } from './criacao-srv-integracao/criacao-srv-integracao.component';
import { AmbienteProducaoComponent } from './ambiente-producao/ambiente-producao.component';
import { AtualizacaoVersaoComponent } from './atualizacao-versao/atualizacao-versao.component';
import { AmbienteConnectComponent } from './ambiente-connect/ambiente-connect.component';
import { PublicacaoHomologacaoComponent } from './publicacao-homologacao/publicacao-homologacao.component';
import { ListModuleintegrationsComponent } from './moduleIntegrations/list-moduleintegrations/list-moduleintegrations.component';
import { EditModuleintegrationsComponent } from './moduleIntegrations/edit-moduleintegrations/edit-moduleintegrations.component';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { ListTypeIntegrationsComponent } from './typeintegrations/list-type-integrations/list-type-integrations.component';
import { EditTypeIntegrationsComponent } from './typeintegrations/edit-type-integrations/edit-type-integrations.component';
import { ListVersionsComponent } from './versions/list-versions/list-versions.component';
import { EditVersionsComponent } from './versions/edit-versions/edit-versions.component';
import { ListProdutoConnectComponent } from './produtos-connect/list-produto-connect/list-produto-connect.component';
import { EditProdutoConnectComponent } from './produtos-connect/edit-produto-connect/edit-produto-connect.component';
import { EditClientComponent } from './client/edit-client/edit-client.component';
import { ListClientComponent } from './client/list-client/list-client.component';
import { RegisterClientComponent } from './client/register-client/register-client.component';
import { ListClientLinksComponent } from './clientlink/list-client-links/list-client-links.component';
import { RegisterClientLinksComponent } from './clientlink/register-client-links/register-client-links.component';
import { EditClientLinksComponent } from './clientlink/edit-client-links/edit-client-links.component';
import { ListUsersComponent } from './usuarios/list-users/list-users.component';
import { EditUsersComponent } from './usuarios/edit-users/edit-users.component';
import { RegisterUsersComponent } from './usuarios/register-users/register-users.component';
import { ListSrvIntegracaoComponent } from './criacao-srv-integracao/list-srv-integracao/list-srv-integracao.component';
import { InfoCriacaoSrvIntegracaoComponent } from './criacao-srv-integracao/info-criacao-srv-integracao/info-criacao-srv-integracao.component';
import { LogCriacaoSrvIntegracaoComponent } from './criacao-srv-integracao/log-criacao-srv-integracao/log-criacao-srv-integracao.component';
import { InforAmbienteConnectComponent } from './ambiente-connect/infor-ambiente-connect/infor-ambiente-connect.component';
import { ListAmbienteConnectComponent } from './ambiente-connect/list-ambiente-connect/list-ambiente-connect.component';
import { LogAmbienteConnectComponent } from './ambiente-connect/log-ambiente-connect/log-ambiente-connect.component';
import { ListAmbienteProducaoComponent } from './ambiente-producao/list-ambiente-producao/list-ambiente-producao.component';
import { InfoAmbienteProducaoComponent } from './ambiente-producao/info-ambiente-producao/info-ambiente-producao.component';
import { LogAmbienteProducaoComponent } from './ambiente-producao/log-ambiente-producao/log-ambiente-producao.component';
import { ListPublicacaoProducaoComponent } from './publicacao-producao/list-publicacao-producao/list-publicacao-producao.component';
import { InfoPublicacaoProducaoComponent } from './publicacao-producao/info-publicacao-producao/info-publicacao-producao.component';
import { LogPublicacaoProducaoComponent } from './publicacao-producao/log-publicacao-producao/log-publicacao-producao.component';
import { ListPublicacaoHomologacaoComponent } from './publicacao-homologacao/list-publicacao-homologacao/list-publicacao-homologacao.component';
import { InfoPublicacaoHomologacaoComponent } from './publicacao-homologacao/info-publicacao-homologacao/info-publicacao-homologacao.component';
import { LogPublicacaoHomologacaoComponent } from './publicacao-homologacao/log-publicacao-homologacao/log-publicacao-homologacao.component';
import { ListPublicacaoProjetosComponent } from './publicacao-projetos/list-publicacao-projetos/list-publicacao-projetos.component';
import { InfoPublicacaoProjetosComponent } from './publicacao-projetos/info-publicacao-projetos/info-publicacao-projetos.component';
import { LogPublicacaoProjetosComponent } from './publicacao-projetos/log-publicacao-projetos/log-publicacao-projetos.component';
import { ListAtualizacaoVersaoComponent } from './atualizacao-versao/list-atualizacao-versao/list-atualizacao-versao.component';
import { InfoAtualizacaoVersaoComponent } from './atualizacao-versao/info-atualizacao-versao/info-atualizacao-versao.component';
import { LogAtualizacaoVersaoComponent } from './atualizacao-versao/log-atualizacao-versao/log-atualizacao-versao.component';
import { ListLogsSistemaComponent } from './logs-sistema/list-logs-sistema/list-logs-sistema.component';
import {TableModule} from 'primeng/table';
import {SliderModule} from 'primeng/slider';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import { ReportClientLinksComponent } from './clientlink/report-client-links/report-client-links.component';
import {TreeTableModule} from 'primeng/treetable';
import {ButtonModule} from 'primeng/button';
import {CarouselModule} from 'primeng/carousel';
import {CardModule} from 'primeng/card';
import { ChangePasswordComponent } from './usuarios/change-password/change-password.component';
import {DialogModule} from 'primeng/dialog';
import { AmbienteHomologacaoComponent } from './ambiente-homologacao/ambiente-homologacao.component';
import { InfoAmbienteHomologacaoComponent } from './ambiente-homologacao/info-ambiente-homologacao/info-ambiente-homologacao.component';
import { ListAmbienteHomologacaoComponent } from './ambiente-homologacao/list-ambiente-homologacao/list-ambiente-homologacao.component';
import { LogAmbienteHomologacaoComponent } from './ambiente-homologacao/log-ambiente-homologacao/log-ambiente-homologacao.component';
import {ChartModule} from 'primeng/chart';
import { AcaoManualComponent } from './acao-manual/acao-manual.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputMaskModule} from 'primeng/inputmask';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { ExclusaoAmbienteComponent } from './exclusao-ambiente/exclusao-ambiente/exclusao-ambiente.component';
import { ListExclusaoAmbienteComponent } from './exclusao-ambiente/list-exclusao-ambiente/list-exclusao-ambiente.component';
import { AcessoBaseComponent } from './acesso-base/acesso-base.component';
import { ListAcessoBaseComponent } from './acesso-base/list-acesso-base/list-acesso-base.component';
import { InfoAcessoBaseComponent } from './acesso-base/info-acesso-base/info-acesso-base.component';
import { LogAcessoBaseComponent } from './acesso-base/log-acesso-base/log-acesso-base.component';
import {FileUploadModule} from 'primeng/fileupload';
import { ListTamanhoNuvemComponent } from './tamanho-nuvem/list-tamanho-nuvem/list-tamanho-nuvem.component';
import { NewListTamanhoNuvemComponent } from './tamanho-nuvem/new-list-tamanho-nuvem/new-list-tamanho-nuvem.component';
import { NewAgendamentoPublicacaoComponent } from './agendamento-publicacao/new-agendamento-publicacao/new-agendamento-publicacao.component';
import { ListAgendamentoPublicacaoComponent } from './agendamento-publicacao/list-agendamento-publicacao/list-agendamento-publicacao.component';
import {CalendarModule} from 'primeng/calendar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CheckboxModule} from 'primeng/checkbox';
import { AllowAgendamentoPublicacaoComponent } from './agendamento-publicacao/allow-agendamento-publicacao/allow-agendamento-publicacao.component';
import { NewAgendamentoBackupComponent } from './agendamento-backup/new-agendamento-backup/new-agendamento-backup.component';
import { ListAgendamentoBackupComponent } from './agendamento-backup/list-agendamento-backup/list-agendamento-backup.component';
import { NewListClientLinksComponent } from './clientlink/new-list-client-links/new-list-client-links.component';
import { NewListClientComponent } from './client/new-list-client/new-list-client.component';
import { NewListProdutoConnectComponent } from './produtos-connect/new-list-produto-connect/new-list-produto-connect.component';
import { NewListModuleintegrationsComponent } from './moduleIntegrations/new-list-moduleintegrations/new-list-moduleintegrations.component';
import { NewListTypeIntegrationsComponent } from './typeintegrations/new-list-type-integrations/new-list-type-integrations.component';
import { NewListVersionsComponent } from './versions/new-list-versions/new-list-versions.component';
import { NewListUsersComponent } from './usuarios/new-list-users/new-list-users.component';
import { CustosAwsComponent } from './custos-aws/custos-aws.component';
import { GridModule } from '@progress/kendo-angular-grid';
import 'hammerjs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import {ExcelModule } from '@progress/kendo-angular-grid';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SplitterModule } from '@progress/kendo-angular-layout';
import { CustoVolumesComponent } from './custo-volumes/custo-volumes.component';
import { RelatorioBaseVersaoComponent } from './relatorio-base-versao/relatorio-base-versao.component';
import { RelatorioAmbientesOneshareComponent } from './relatorio-ambientes-oneshare/relatorio-ambientes-oneshare.component';
import { RelatorioBaseInfoUsoComponent } from './relatorio-base-info-uso/relatorio-base-info-uso.component';



@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpModule,
    TableModule,
    SliderModule,
    DropdownModule,
    MultiSelectModule,
    TreeTableModule,
    ButtonModule,
    CarouselModule,
    CardModule,
    DialogModule,
    ChartModule,
    InputTextareaModule,
    InputMaskModule,
    DropdownModule,
    AutoCompleteModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
    CalendarModule,
    ConfirmDialogModule,
    CheckboxModule,
    GridModule,
    ChartsModule,
    ExcelModule,
    TreeListModule,
    LayoutModule,
    SplitterModule,
    
    
  ],
  declarations: [
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    PublicacaoProducaoComponent,
    PublicacaoProjetosComponent,
    CriacaoSrvIntegracaoComponent,
    AmbienteProducaoComponent,
    AtualizacaoVersaoComponent,
    AmbienteConnectComponent,
    PublicacaoHomologacaoComponent,
    ListModuleintegrationsComponent,
    EditModuleintegrationsComponent,
    ListTypeIntegrationsComponent,
    EditTypeIntegrationsComponent,
    ListVersionsComponent,
    EditVersionsComponent,
    ListProdutoConnectComponent,
    EditProdutoConnectComponent,
    RegisterClientComponent,
    EditClientComponent,
    ListClientComponent,
    ListClientLinksComponent,
    RegisterClientLinksComponent,
    EditClientLinksComponent,
    ListUsersComponent,
    EditUsersComponent,
    RegisterUsersComponent,
    ListSrvIntegracaoComponent,
    InfoCriacaoSrvIntegracaoComponent,
    LogCriacaoSrvIntegracaoComponent,
    InforAmbienteConnectComponent,
    ListAmbienteConnectComponent,
    LogAmbienteConnectComponent,
    ListAmbienteProducaoComponent,
    InfoAmbienteProducaoComponent,
    LogAmbienteProducaoComponent,
    ListPublicacaoProducaoComponent,
    InfoPublicacaoProducaoComponent,
    LogPublicacaoProducaoComponent,
    ListPublicacaoHomologacaoComponent,
    InfoPublicacaoHomologacaoComponent,
    LogPublicacaoHomologacaoComponent,
    ListPublicacaoProjetosComponent,
    InfoPublicacaoProjetosComponent,
    LogPublicacaoProjetosComponent,
    ListAtualizacaoVersaoComponent,
    InfoAtualizacaoVersaoComponent,
    LogAtualizacaoVersaoComponent,
    ListLogsSistemaComponent,
    ReportClientLinksComponent,
    ChangePasswordComponent,
    AmbienteHomologacaoComponent,
    InfoAmbienteHomologacaoComponent,
    ListAmbienteHomologacaoComponent,
    LogAmbienteHomologacaoComponent,
    AcaoManualComponent,
    ExclusaoAmbienteComponent,
    ListExclusaoAmbienteComponent,
    AcessoBaseComponent,
    ListAcessoBaseComponent,
    InfoAcessoBaseComponent,
    LogAcessoBaseComponent,
    ListTamanhoNuvemComponent,
    NewListTamanhoNuvemComponent,
    NewAgendamentoPublicacaoComponent,
    ListAgendamentoPublicacaoComponent,
    AllowAgendamentoPublicacaoComponent,
    NewAgendamentoBackupComponent,
    ListAgendamentoBackupComponent,
    NewListClientLinksComponent,
    NewListClientComponent,
    NewListProdutoConnectComponent,
    NewListModuleintegrationsComponent,
    NewListTypeIntegrationsComponent,
    NewListVersionsComponent,
    NewListUsersComponent,
    CustosAwsComponent,
    CustoVolumesComponent,
    RelatorioBaseVersaoComponent,
    RelatorioAmbientesOneshareComponent,
    RelatorioBaseInfoUsoComponent
  ],
  exports: [
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    PublicacaoProducaoComponent
  ]

})
export class ComponentsModule { }
