import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import { VersionsService } from './versions.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-register-version',
  templateUrl: './register-version.component.html',
  styleUrls: ['./register-version.component.css']
})
export class RegisterVersionComponent implements OnInit {

  form: FormGroup
  previous
  constructor(private formBuilder: FormBuilder,
    private http: Http,
    private router: Router,  
    private versionservice: VersionsService,
    private location: Location ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      versao: [null, [Validators.required]]
    })
    
  }
  onSubmit(){
    
    if(this.form.valid){
      this.versionservice.create(this.form.value).subscribe(
        success => this.location.back(),
        error => alert(error.error.error)
      )
    } else{
      Object.keys(this.form.controls).forEach((campo)=> {
        const controle = this.form.get(campo)
        controle.markAsTouched()
      })
    }
    
  }

  onBack(){
    this.location.back()
  }
  
  aplicaCssErro(campo){
    return {
      'has-error': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    }
  }
  verificaValidTouched(campo){
    return !this.form.get(campo).valid && this.form.get(campo).touched
  }

}
