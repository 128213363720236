import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgendamentoPublicacaoService } from '../agendamento-publicacao.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client/client.service';
import { Location, formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/authentication.service';
import { VersionsService } from 'src/app/shared/register-version/versions.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-new-agendamento-publicacao',
  templateUrl: './new-agendamento-publicacao.component.html',
  styleUrls: ['./new-agendamento-publicacao.component.css']
})
export class NewAgendamentoPublicacaoComponent implements OnInit {

  today = new Date()
  hours: string[]
  versoes: any[]
  hoursObj: any
  mostrar: any = 0
  form: FormGroup;
  clients: any
  ambientes: any
  scheddules: any
  displayModal: boolean
  modalText: any

  constructor(
    private formBuilder: FormBuilder,
    private agendamentoPublicacaoService: AgendamentoPublicacaoService,
    private versionservice: VersionsService,
    private location: Location,
    private clientService: ClientService,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
    this.today.setDate(this.today.getDate())

    this.hours = ['00:00', '00:30', '01:00', '01:30', '02:00', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '18:30', '19:00', '19:30', '20:00']
    this.form = this.formBuilder.group({
      url: [null, [Validators.required]],
      approver: [null],
      pat: [null, Validators.required],
      enviroment: [null, Validators.required],
      urlHibrido: [null, Validators.required],
      ClientId: [null],
      versao: [null, Validators.required],
      empresa: [null],
      date: [null, Validators.required],
      hour: [null, Validators.required],
      user: [null, Validators.required]
    });
    this.ambientes = [
      { name: 'Produção', value: 'producao' },
      { name: 'Homologação', value: 'homologacao' },
      { name: 'Projetos', value: 'projetos' },
    ]
    this.hoursObj = []
    this.hours.forEach(element => {
      this.hoursObj.push({ name: element, value: element })
    })

    this.versionservice.getall().subscribe(versions => {
      this.versoes = []
      versions.forEach(version => {
        this.versoes.push({ versao: version.versao })
      })
    })

    this.auth.profile().subscribe(
      user => {
        this.form.get('user').setValue(user.email)
      },
      err => {
        console.error(err)
      }
    )

    this.clientService.getAll().subscribe(clients => {
      this.clients = []
      clients.forEach(client => {
        this.clients.push({ name: client.name, id: client.id })
      })
    })
  }

  onSubmit() {
    if (this.form.valid) {
      this.form.get('date').setValue(formatDate(this.form.get('date').value, 'dd/MM/yyyy', 'en'))
      this.agendamentoPublicacaoService.post(this.form.value).pipe(map(res => res)).subscribe(dados => {
        this.form.get('url').setValue(null)
        this.form.get('url').markAsUntouched();
        this.form.get('urlHibrido').setValue(null)
        this.form.get('urlHibrido').markAsUntouched();
        this.form.get('empresa').setValue(null)
        this.form.get('empresa').markAsUntouched();
        this.form.get('approver').setValue(null)
        this.form.get('approver').markAsUntouched();
        this.form.get('enviroment').setValue(null)
        this.form.get('enviroment').markAsUntouched();
        this.form.get('pat').setValue(null)
        this.form.get('pat').markAsUntouched();
        this.form.get('ClientId').setValue(null)
        this.form.get('ClientId').markAsUntouched();
        this.form.get('versao').setValue(null)
        this.form.get('versao').markAsUntouched();
        this.form.get('date').setValue(null)
        this.form.get('date').markAsUntouched();
        this.form.get('hour').setValue(null)
        this.form.get('hour').markAsUntouched();

        this.modalText = "Agendamento feito"
        this.displayModal = true
      },
        (error: any) => {
          this.modalText = error.message
          this.displayModal = true
          this.form.get('date').setValue(null)
          this.form.get('date').markAsUntouched();
        })
    } else {
      this.form.get('date').setValue(null)
      this.form.get('date').markAsUntouched();
      Object.keys(this.form.controls).forEach((campo) => {
        const controle = this.form.get(campo);
        controle.markAsTouched();
      });
    }
  }
  onBack() {
    this.location.back();
  }

  trocar() {

    if (this.form.get('enviroment').value.value == 'projetos') {
      this.mostrar = 2
      this.form.get('urlHibrido').setValue(null)
    } else if(this.form.get('enviroment').value.value == 'producao'){
      this.mostrar = 1
      this.form.get('ClientId').setValue(null)
    } else {
      this.mostrar = 0
      this.form.get('ClientId').setValue(null)
      this.form.get('urlHibrido').setValue(null)
    }
  }

  dataSelecionada() {
    this.hours = ['00:00', '00:30', '01:00', '01:30', '02:00', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '18:30', '19:00', '19:30', '20:00']
    this.form.get('hour').setValue(null)
    this.agendamentoPublicacaoService.getHoursByDay(formatDate(this.form.get('date').value, 'dd/MM/yyyy', 'en')).subscribe(scheddules => {
      this.hoursObj = []
      this.scheddules = []
      scheddules.forEach(scheddule => {
        this.scheddules.push(scheddule.time)
      })
      for (let index = 0; index < this.scheddules.length; index++) {
        const element = this.scheddules[index];
        for (let z = 0; z < this.hours.length; z++) {
          const elementHour = this.hours[z];
          if (element == elementHour) {
            this.hours.splice(z, 1)
          }
        }
      }
      this.hours.forEach(element => {
        this.hoursObj.push({ name: element, value: element })
      })
    })
  }

}
