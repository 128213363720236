import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-acesso-base',
  templateUrl: './log-acesso-base.component.html',
  styleUrls: ['./log-acesso-base.component.css']
})
export class LogAcessoBaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
