import { Component, OnInit } from '@angular/core';
import { TamanhoNuvemModel } from 'src/app/models/tamanho-nuvem';
import { TamanhoNuvemService } from '../tamanho-nuvem.service';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-list-tamanho-nuvem',
  templateUrl: './list-tamanho-nuvem.component.html',
  styleUrls: ['./list-tamanho-nuvem.component.css']
})
export class ListTamanhoNuvemComponent implements OnInit {

  data: TamanhoNuvemModel[]
  fileName= formatDate(new Date(),'dd/MM/yyyy', 'en') + 'TamanhoClientes.xlsx';
  cols: any[]
  constructor(
    private tamanhoNuvemService: TamanhoNuvemService
    ) { }
 
  ngOnInit(): void {
    this.tamanhoNuvemService.getall().subscribe(data=> this.data = data)

    this.cols = [
      { field: 'cliente', header: 'Cliente' },
      { field: 'total', header: 'Tamanho Total' },
      { field: 'anexos', header: 'Anexos' },
      { field: 'arquivos', header: 'Arquivos' },
      { field: 'bpmsimagens', header: 'BPMSimagens' },
      { field: 'chavederegistro', header:'ChaveDeRegistro'},
      { field: 'configuracoes', header: 'Configurações' },
      { field: 'icones', header: 'Icones' },
      { field: 'imagensmeuslinks', header: 'ImagensMeusLinks' },
      { field: 'informativos', header: 'Informativos'},
      { field: 'mensagens', header: 'Mensagens'},
      { field: 'mxmconfigcomum', header: 'MXMConfigComum'},
      { field: 'temp', header: 'Temp'},
      { field: 'temporario', header: 'Temporario'},
      { field: 'treinamento', header: 'Treinamento'},
      { field: 'usuarios', header: 'Usuarios'}
    ]

  }

  exportexcel(){
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}
