import { Component, ViewEncapsulation } from '@angular/core'
import { AuthenticationService, TokenPayload } from '../../authentication.service'
import { Router } from '@angular/router'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  credentials: TokenPayload = {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    profile:'',
    password: ''
  }
  displayModal: boolean
  modalText: any

  constructor(private auth: AuthenticationService, private router: Router) {}

  login() {
    this.auth.login(this.credentials).subscribe(
      () => {
        this.router.navigateByUrl('/')
      },
      err => {
        this.modalText = "Usuário ou Senha Incorreto"
        this.displayModal = true
      }
    )
  }
}
