import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService, UserDetails } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NotVisualizacaoService implements CanActivate {

  userDetail: UserDetails

  constructor(private auth: AuthenticationService, private router: Router) { }

  canActivate() {
    if (!this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/login')
      return false
    }
    this.auth.profile().subscribe(
      user => {
        this.userDetail = user
        if(this.userDetail.profile == "visualizacao"){
          this.router.navigateByUrl('/permissaonegada')
          return false
          
        }
      },
      err => {
        console.error(err)
      }
    )
  
    // if(this.userDetail.profile =="visualizacao"){
    //   this.router.navigateByUrl('/')
    //   return false
    // }
    return true
  }


}
