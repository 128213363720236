import { Component, OnInit } from '@angular/core';
import { ProdutoConnectService } from 'src/app/shared/register-produto-connect/produto-connect.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-produto-connect',
  templateUrl: './list-produto-connect.component.html',
  styleUrls: ['./list-produto-connect.component.css']
})
export class ListProdutoConnectComponent implements OnInit {

  items = []
  produtos = []
  pageOfItems: Array<any>

  constructor(
    private produtoConnectService: ProdutoConnectService,
    private router: Router ) { }

  ngOnInit() {
    this.produtoConnectService.getAll().subscribe(dados => {
      dados.forEach((x)=>{
        if(x.disabled===0){
          this.produtos.push(x)
        }
      })
      this.produtos = this.produtos.sort((a,b) =>(a.name > b.name)? 1: -1)
      this.items = Array(0);
      for (let i = 0; i < this.produtos.length; i++) {
        this.items.push(this.produtos[i])
      }
      
    })
    
  }

  onEdit(id){
    this.router.navigate(['connect/produtos/editar', id])
  }

  onDelete(id){
    this.produtoConnectService.disable(id).subscribe(dados =>{
      console.log(dados)
    })
    location.reload()
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
}
