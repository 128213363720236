import { Component, OnInit } from '@angular/core';
import { PublicacaoProducaoServiceService } from '../publicacao-producao-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-publicacao-producao',
  templateUrl: './list-publicacao-producao.component.html',
  styleUrls: ['./list-publicacao-producao.component.css']
})
export class ListPublicacaoProducaoComponent implements OnInit {

  items = []
  builds = []
  pageOfItems: Array<any>;
  loading=true;

  

  constructor(
    private publicacaoProducaoServiceService: PublicacaoProducaoServiceService,
    private router: Router) { }

  ngOnInit() {
    this.publicacaoProducaoServiceService.getBuilds().subscribe(dados => {
      dados.forEach((x) => {
        this.builds.push(x)
      })
      this.builds = this.builds.sort((a, b) => (a.number < b.number) ? 1 : -1)
      this.items = Array(0);
      for (let i = 0; i < this.builds.length; i++) {
        this.items.push(this.builds[i])
      }
      this.loading=false;
      
    })

  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onInfo({dataItem}) {
    this.router.navigate(['publicacao/producao/info', dataItem.number])
  }
 
}
