import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AmbienteProducaoService } from '../ambiente-producao.service';

@Component({
  selector: 'app-info-ambiente-producao',
  templateUrl: './info-ambiente-producao.component.html',
  styleUrls: ['./info-ambiente-producao.component.css']
})
export class InfoAmbienteProducaoComponent implements OnInit {

  parameters=[]
  id: number
  build
  date
  
  constructor(
    private route: ActivatedRoute,
    private ambienteProducaoService: AmbienteProducaoService,
    private location: Location,
    private router: Router
    
    ) { }

  ngOnInit() {
    this.route.params.subscribe((params: any)=>{
      this.id = params['id']
    })
    this.ambienteProducaoService.getBuildsById(this.id).subscribe((dados)=>{
      this.build = dados
      this.parameters = this.build.actions[0].parameters
      this.date = new Date(dados.timestamp)
    })
  }

  onBack(){
    this.location.back()
    
  }
  onLog(){
    this.router.navigate(['ambiente/criacao/producao/log', this.id])
  }
 

}
