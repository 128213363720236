import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication.service';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcaoManualService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(acao: any): Observable<any> {

    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })
      
    return this.http.post(`${GlobalConstants.apiURL}/executions/manual-action`, acao, {headers, responseType: 'text'}).pipe(take(1))
  }

}
