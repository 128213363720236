import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.css']
})
export class PermissionDeniedComponent implements OnInit {

  displayModal: boolean = true

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  clickConfirmation(){
    this.displayModal = false
    this.router.navigateByUrl('/')
  }

}
