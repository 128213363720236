import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/global-constants';
import { take } from 'rxjs/operators';
import { AmbienteConnectModel } from 'src/app/models/ambiente-connect';

@Injectable({
  providedIn: 'root'
})
export class AmbienteConnectService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public post(ambiente: AmbienteConnectModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })
      

    return this.http.post(`${GlobalConstants.apiURL}/enviroments/connect`, ambiente, {headers, responseType: 'text'}).pipe(take(1))
  }

  public getBuilds(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/enviroments/connect/builds`, httpOptions)
  }

  public getBuildsById(id): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/enviroments/connect/builds/${id}`, httpOptions)
  }

  public getLogById(id): Observable<any>{
    const headers = new HttpHeaders({
      'Authorization': this.auth.getToken()
    })

    return this.http.get(`${GlobalConstants.apiURL}/enviroments/connect/builds/log/${id}`,{headers, responseType: 'text'})
  }

  public getRules(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.auth.getToken()
      })
    };

    return this.http.get(`${GlobalConstants.apiURL}/commons/describeRulesConnect`, httpOptions)
  }
  
}
