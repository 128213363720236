import { Component, OnInit } from '@angular/core';
import { AmbienteConnectService } from '../ambiente-connect.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-ambiente-connect',
  templateUrl: './list-ambiente-connect.component.html',
  styleUrls: ['./list-ambiente-connect.component.css']
})
export class ListAmbienteConnectComponent implements OnInit {

  items = []
  builds = []
  pageOfItems: Array<any>;
  loading=true;

  

  constructor(
    private ambienteConnectService: AmbienteConnectService,
    private router: Router) { }

  ngOnInit() {
    this.ambienteConnectService.getBuilds().subscribe(dados => {
      dados.forEach((x) => {
        this.builds.push(x)
      })
      this.builds = this.builds.sort((a, b) => (a.number < b.number) ? 1 : -1)
      this.items = Array(0);
      for (let i = 0; i < this.builds.length; i++) {
        this.items.push(this.builds[i])
      }
      this.loading=false;
      
    })

  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onInfo({dataItem}) {
    this.router.navigate(['ambiente/criacao/connect/info', dataItem.number])
  }

} 
